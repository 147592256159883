@import '../global/global';
@import '../requestPasswordReset';
@import '../resetPassword';
@import '../registerForm';
@import '../togglePassword';

.loyalty-early-access-register-modal,
.customer-section-login-modal {
    .modal-dialog {
        max-width: rem(960);

        .container {
            display: block;
        }
    }

    .close-sign-in-modal {
        padding-top: rem(16);
    }

    .tabbable-panel {
        padding: rem(24) rem(16) 0;

        .tab-content {
            margin-right: rem(-16);
            margin-left: rem(-16);

            .sign-in-container {
                padding: 0;

                .forgot-password {
                    text-align: center;
                    margin-top: rem(24);
                    font-size: rem(12);
                    line-height: rem(24);
                }
                
                .tertiary-link {
                    font-size: rem(12);
                    line-height: rem(24);
                    letter-spacing: rem(0.4);
                }

                .remember-me {
                    margin-bottom: 0;
                }

                .account-login-btn {
                    margin-top: rem(40);
                }
            }

            .create-account-container .valid-password-info svg {
                width: rem(12);
                height: rem(12);
            }
        }

        @include media-breakpoint-up(md) {
            padding: rem(10) rem(60) 0;

            .tab-content {
                margin: 0;

                .sign-in-container {
                    padding: 0 rem(16);
                }
            }
        }
    }

    .loyalty-early-access-register-dialog,
    .customer-section-login-dialog {
        .login {
            .info-text {
                display: none;
            }
        }

        .early-access-register-nav .early-access-register-nav-tabs,
        .customer-checkout-login-nav .customer-checkout-login-nav-tabs {
            font-family: $gotham-regular;
            color: $base-text-color;
            border-bottom: 0;
            font-size: rem(14);
            line-height: rem(20);
            margin: 0 rem(-16);

            @include media-breakpoint-up(md) {
                margin: 0;
            }

            .early-access-register-list {
                width: 50%;
            }

            .customer-checkout-login-tab {
                width: 100%;
            }

            .early-access-register-list,
            .customer-checkout-login-tab {
                text-align: center;
                border-bottom: rem(1) solid $border-bottom-nav-tab;

                a {
                    display: block;
                    padding: 0 0 rem(16);
                    text-decoration: none;
                }

                a.active {
                    font-family: $gotham-semibold;
                    font-weight: 500;
                    border-bottom: rem(2) solid $selected-nav-tab;
                }
            }
        }

        .remember-me {
            margin-top: rem(24);
            margin-bottom: 0;
            width: 100%;
            float: left;
        }

        .submit-customer-login {
            margin-top: rem(40);
        }

        .keep-user-signed-in {
            margin-top: rem(4);
            margin-left: rem(3);

            &:hover {
                background-color: $icon-background-color;
            }
        }

        .create-account-page-title {
            margin: 0;
        }

        .sign-in-container,
        .create-account-container {
            margin: rem(40) auto rem(32);

            .early-access-sign-info,
            .customer-checkout-sign-in {
                font-size: rem(12);
                line-height: rem(16);
                letter-spacing: rem(0.4);
                color: $loyalty-heading-color;
                margin-bottom: rem(24);
            }

            .pdp-sign-in-info {
                color: $base-text-color;
                font-family: $gotham-regular;
                font-size: rem(14);
                font-weight: $font-weight-regular;
                letter-spacing: 0;
                line-height: rem(20);
                padding: 0 rem(32) rem(24);

                @include media-breakpoint-up(md) {
                    padding: 0 0 rem(24);
                }
            }

            .early-access-sign-in-text,
            .customer-checkout-sign-in-text {
                font-size: rem(32);
                font-family: $crimson;
                line-height: rem(40);
                margin-bottom: 0;
            }

            .early-access-login-form,
            .customer-checkout-login-form {
                .forgot-password {
                    text-align: center;
                    margin-top: rem(24);
                }
            }

            .early-access-register-form {
                margin: 0;

                .registration {
                    .create-account-heading {
                        margin-left: 0;

                        h1::before {
                            display: none;
                        }
                    }

                    .registration-left {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.customer-section-login-modal {
    .customer-checkout-login-tab {
        width: 100%;
    }
}