#email-flyin-v1 {
    .modal-dialog {
        height: rem(616);
        max-width: rem(560);
        width: rem(560);
        z-index: $zindex-max-highest;

        @include media-breakpoint-down(sm) {
            height: auto;
            width: auto;
        }
    }

    .modal-content {
        background: $alert-info-background-color;
        border: none;
        height: 100%;
        text-align: center;
    }

    .flyin-center-email,
    .flyin-center-mobile-number {
        text-align: left;
    }

    .email-subscribe-thanks {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .flyin-first {
            text-transform: uppercase;
        }

        .flyin-second {
            font-size: rem(14);
        }
    }

    .close-email-v1 {
        position: absolute;
        right: rem(15);
        top: rem(15);
        cursor: pointer;
    }

    .flyin-first {
        color: $lilly-pink;
        font-size: rem(22);
        font-family: $gotham-semibold;
        line-height: rem(28);
        margin: rem(24) 0 rem(10) 0;
        padding: 0 rem(32);

        @include media-breakpoint-down(sm) {
            padding: 0 rem(48) rem(12);
        }

    }

    .flyin-second {
        color: $lilly-pink;
        font-size: rem(14);
        font-family: $gotham-regular;
        line-height: rem(20);
        margin: 0 rem(48) rem(32);
        padding: 0 rem(32);

        @include media-breakpoint-down(sm) {
            font-size: rem(14);
            margin: rem(5) 0 0;
            padding: 0 rem(48);
        }
    }

    .flyin-last {
        color: $base-text-color;
        font-size: rem(12);
        line-height: rem(16);
        font-family: $gotham-regular;
        text-align: left;
        margin-bottom: rem(40);

        @include media-breakpoint-down(sm) {
            padding-bottom: rem(40);
        }

        a {
            text-decoration: underline;
        }
    }

    .fly-email-label::after {
        content: "*";
    }

    .btn-no-thanks {
        text-decoration: underline;
        margin: rem(16) 0 rem(24);
        color: $base-text-color;
        font-size: rem(12);
        font-family: $gotham-regular;
    }

    form.usr-email-wrap {
        margin: 0 auto;
        position: relative;
        width: rem(360);

        @include media-breakpoint-down(sm) {
            width: rem(311);
        }
    }

    .email-submit-button {
        margin-top: rem(24);
    }

    .favicon-lilly {
        margin-top: rem(50);
        width: rem(24);
    }
}
