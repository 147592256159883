@import '../recommendations';

.not-found-container {
    margin: rem(88) auto 0;

    .error-message {
        font-size: rem(24);
        font-family: $crimson;
        line-height: rem(32);
        margin-bottom: rem(16);
        margin-top: 0;
    }

    .error-info {
        margin-bottom: rem(32);
        letter-spacing: rem(0.3);
        font-family: $gotham-semibold;
    }

    .recommendations {
        margin-top: rem(88);
    }

    .product-listing-button {
        display: none;
    }
    .error {
        &.continue-shopping {
            max-width: rem(304);
            margin: 0 auto;
        }
    }

    @include media-breakpoint-up(md) {
        margin: rem(144) auto 0;

        .error-message {
            font-size: rem(32);
            line-height: rem(40);
            margin-bottom: rem(16);
            margin-top: 0;
        }

        .recommendations {
            margin-top: rem(144);
        }

    }
}
.order-detail-error {
    line-height: rem(20);
    width: rem(293);
    margin-left: auto;
    margin-right: auto;
}
.modal-background {
    z-index: $zindex-lowest;
}

.rendering-template {
    display: inline-block;
}
