// Mobile drop-down
.pills-card-selector {
    position: relative;
    margin-top: rem(24);
    margin-bottom: rem(40);
    border-bottom: rem(1) solid $account-cards-selector-border-color;
    border-top: rem(1) solid $account-cards-selector-border-color;

    .pills-card-select-btn {
        width: 100%;
        padding: 0 rem(16);
        text-align: left;
        text-decoration: none;

        &::after {
            position: absolute;
            content: '';
            right: rem(16);
            top: rem(14);
            transform: rotate(45deg);
            height: rem(12);
            width: rem(12);
            border-bottom: rem(1) solid $account-cards-selector-arrow-color;
            border-right: rem(1) solid $account-cards-selector-arrow-color;
        }

        &[aria-expanded='true'] {
            &::after {
                transform: rotate(225deg);
                top: rem(22);
            }
        }
    }

    .pill-cards-menu {
        margin: 0;
        border: rem(1) solid $account-cards-selector-border-color;
        box-shadow: 0 rem(4) rem(12) 0 rgba(0, 0, 0, 0.1);
    }

    .pill-card-btn {
        font-family: $gotham-regular;
        padding: 0 rem(16);
        width: 100%;
        text-align: left;
    }
}

// Desktop Pills Menu
.top-tabs {
    font-family: $gotham-semibold;
    font-size: rem(14);
    line-height: rem(24);
    color: $base-text-color;

    &::after {
        position: relative;
        right: rem(16);
    }

    @include media-breakpoint-up(md) {
        li {
            color: $base-text-color;
            font-family: $gotham-regular;
            font-size: rem(14);
            line-height: rem(20);
            text-align: center;
            margin-right: rem(20);
            margin-bottom: rem(48);

            a {
                text-decoration: none;
                padding-bottom: rem(18);

                &.active {
                    border-bottom: rem(3) solid black;
                }
            }
        }
    }
}
