.product-tile {
    overflow-x: auto;
    position: relative;
    text-align: center;
    z-index: 0;
    overflow-y: hidden;

    .carousel-control {
        top: 0;
        bottom: 0;
        height: 25%;
        opacity: 0;
        margin: auto;
        transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;

        &-prev {
            padding-left: rem(8);
        }

        &-next {
            padding-right: rem(8);
        }
    }

    .image-container {
        position: relative;
        text-align: center;
        min-height: rem(209);

        .tile-img-link {
            img {
                width: 100%;
                display: block;
            }

            &:focus {
                outline: rem(2) dotted $mulled-wine;
                box-shadow: none;
            }
        }

        @include media-breakpoint-up(md) {
            min-height: rem(206);
        }

        @include media-breakpoint-up(lg) {
            min-height: rem(286);
        }

        .quickview {
            font-family: $gotham-regular;
            font-size: rem(12);
            line-height: rem(16);
            letter-spacing: rem(0.4);
            opacity: 0;
            position: absolute;
            bottom: rem(16);
            left: rem(10);
            width: calc(100% - 20px);
            padding: rem(12) 0;
            text-decoration: none;
            background-color: $quick-view-button-background-color;
            border: rem(1) solid $quick-view-button-border-color;
            color: $quick-view-text-color;
            z-index: $zindex-lowest;
            transition: opacity 0.3s;

            &:hover {
                text-decoration: none;
            }
        }

        &:hover {
            .quickview {
                opacity: 1;
            }

            @include media-breakpoint-up(md) {

                .product-tile-badge,
                .loyalty-product-tile-badge {
                    opacity: 0;
                }
            }

            .alt-img {
                display: block;
            }

            .carousel-control {
                opacity: 1;
            }
        }

        .quickview:focus {
            opacity: 1;
            outline: rem(2) dotted $mulled-wine;
            box-shadow: none;
        }

        .carousel-control:focus {
            opacity: 1;
            outline: rem(2) dotted $mulled-wine;
            box-shadow: none;
        }
    }

    .pdp-link {
        margin-top: rem(8);
        margin-bottom: rem(5);
        font-family: $crimson;
        font-size: rem(14);
        line-height: rem(20);
        text-align: left;
        color: $grey-text;

        .link {
            line-height: rem(20);

            &:hover {
                text-decoration: none;
            }
        }
    }

    .tile-body {
        padding-top: 0;
        padding-bottom: rem(32);

        @include media-breakpoint-up(md) {
            padding-bottom: rem(48);
        }

        .pdp-link {
            a {
                font-size: rem(14);
                text-decoration: none;
            }

            h3 {
                font-size: rem(14);
                font-family: $crimson;
                line-height: rem(20);
                font-weight: $font-weight-regular;
                display: inline;
                margin: 0;
            }
        }

        .price {
            font-family: $gotham-semibold;
            font-size: rem(12);
            line-height: rem(16);
            color: $price-color;
            letter-spacing: rem(0.4);
            text-align: left;
            margin-bottom: rem(8);

            del+.sales {
                color: $sale-price-color;
            }
        }

        .color-swatches {
            text-align: left;
            min-height: unset;

            .swatch-circle {
                width: rem(16);
                height: rem(16);
                margin-right: 0;
                border: rem(1) solid $swatch-selection-border-color;
            }

            .swatches {
                &-more-colors {
                    font-size: rem(12);
                    line-height: rem(24);
                    letter-spacing: rem(0.4);
                    text-decoration: underline;
                }

                &-more {
                    text-decoration: none;
                }
            }

            .swatches-empty {
                font-family: $gotham-semibold;
                font-size: rem(12);
                line-height: rem(16);
                color: $price-color;
                letter-spacing: rem(0.4);
                text-align: left;
                margin-bottom: rem(8);
            }

            .product-tile-swatch {
                margin-right: rem(8);
                padding: rem(1);
                display: flex;
                align-items: center;
                justify-content: center;

                &.selected {
                    border: rem(1) solid $active-border;
                    border-radius: 100%;
                    width: rem(17);
                    height: rem(17);
                    position: relative;

                    img {
                        width: rem(13);
                        height: rem(13);
                    }
                }
            }
        }
    }

    .product-tile-badge,
    .loyalty-product-tile-badge {
        padding: rem(8);
        background: rgba($product-tile-background-color, 0.8);
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 1;
        z-index: $zindex-earlyaccess-badge;

        &-text {
            font-size: rem(12);
            line-height: normal;
            letter-spacing: rem(0.4);
            color: $grey-text;
            font-family: $gotham-semibold;
        }
    }

    .loyalty-product-tile-badge {
        background: $loyalty-badge-background-color;

        .loyalty-product-tile-badge-text {
            color: $loyalty-badge-text-color;
        }
    }
}

.add-to-wish-list,
.added-to-wish-list,
.strike-wish-list,
.loyalty-early-access-locked {
    width: rem(24);
    height: rem(24);
    position: absolute;
    z-index: $zindex-low;
    top: rem(16);
    right: rem(16);
    font-size: inherit;
    line-height: normal;
    border: none;
}

.loyalty-early-access-locked {
    width: rem(30);
    padding-right: 0;

    @include media-breakpoint-up(md) {
        width: rem(24);
        padding-right: rem(6);
    }
}

.add-to-wish-list, .strike-wish-list {
    .svg-wishlist-icon {
        display: block;
    }

    .svg-wishlist-active-icon {
        display: none;
    }
}

.added-to-wish-list {
    .svg-wishlist-icon {
        display: none;
    }

    .svg-wishlist-active-icon {
        display: block;
    }
}

.price {
    .list+.sales {
        display: block;
    }

    del:not(.d-none)+.sales {
        color: $sale-price-color;
        margin-left: rem(8);
    }

    .percent-off+.sales {
        display: block;
        color: $sale-price-color;
    }

    .pricing {
        color: $price-color;
    }

    .strike-through {
        &:not(.non-adjusted-price) {

            +.pricing,
            +.price-section .sales {
                color: $sale-price-color;
            }
        }
    }

    .list-range {

        .list+.price-section,
        .price-section+.price-section {
            .sales {
                color: $sale-price-color;
                margin-left: rem(8);
            }
        }

        .percent-off+.price-section .sales {
            display: block;
            color: $sale-price-color;
        }

        .range-price-section {
            +.price-section .sale-selected {
                display: block;
                margin-left: 0;
            }
        }

        .price-section .sale-selected-range.sale-selected {
            display: block;
            margin-left: 0;
        }
    }

    .strike-through {
        color: $price-color;
        margin-right: 0;
    }

    .sales {
        color: $price-color;
    }

    .sale-selected {
        color: $sale-price-color;
    }

    .percent-off {
        font-size: rem(10);
        margin-left: rem(8);
        color: $percent-off-color;
        vertical-align: top;
        font-family: $gotham-regular;
    }

    .starting,
    .range,
    .sales {
        font-weight: $font-weight-medium;
    }

    .range {
        .sales {
            color: $price-color;
        }

        del:not(.d-none)+.sales {
            color: $sale-price-color;
        }
    }
}

.attributes .attribute,
.attribute {
    .selected-swatch-name {
        font-family: $crimson;
        font-size: rem(14);
        line-height: rem(20);
    }

    .is-on-sale {
        .color-attribute {
            &::after {
                content: '';
                width: rem(4);
                height: rem(4);
                background-color: $sale-swatch-indicator-color;
                position: absolute;
                border-radius: 50%;
                top: 0;
                right: 0;
            }
        }
    }

    .color-list {
        margin-bottom: rem(16);
        margin-right: rem(12);
    }

    .color-attribute {
        height: rem(40);
        width: rem(40);
        position: relative;

        span.swatch-circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            margin-right: 0;
            position: static;
            vertical-align: middle;
            display: inline-block;
            background-size: contain;

            &.selected {
                height: rem(32);
                width: rem(32);

                &::after {
                    border: rem(1) solid $selecte-swatch-border-color;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                    content: '';
                    background: transparent;
                    position: absolute;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.final-sale-message {
    font-size: rem(12);
    margin-top: rem(16);
    line-height: rem(16);
    letter-spacing: rem(0.4);
    color: $availability-error-message-color;
    max-width: rem(375);
}

.availability-message .availability-message-text {
    color: $availability-error-message-color;
    font-size: rem(12);
    max-width: rem(375);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .plus-more-colors {
        width: rem(48);
        white-space: nowrap;
        overflow: hidden;
    }
}

.add-to-cart-messages {
    z-index: $zindex-highest;
    top: rem(134);
    transform: translate(-47%, -50%);
    left: 47%;

    .add-to-basket-alert {
        border-radius: 0;
        border: none;
        background-color: $add-to-cart-message-background;
        color: $add-to-cart-message-color;
        font-family: $gotham-regular;
        font-size: rem(14);
        line-height: rem(20);
    }
}

@include media-breakpoint-down(sm) {
    .add-to-cart-messages {
        transform: none;
        left: initial;
        top: rem(48);
    }
}

.primary-images,
.product-tile {
    .carousel-item {
        padding-top: calc(5 / 4 * 100%);
        position: relative;

        @include media-breakpoint-up(md) {
            padding-top: unset;
        }

        .video-component,
        img {
            position: absolute;
            top: 0;

            @include media-breakpoint-up(md) {
                position: unset;
                top: unset;
            }
        }
    }
}

.neon-badge-indicator {
    color: $swatch-neon-color;
    font-size: rem(10);
    line-height: rem(16);
    font-weight: $font-weight-regular;
    margin-top: rem(4);
    text-align: center;

    p {
        margin: 0;
    }
}

.flex {
    display: flex;
}

.loyalty-early-access-lock-container {
    .early-access-container-tooltip {
        display: none;
    }
}

.loyalty-early-access-locked {
    .active-lock-icon {
        display: none;
    }
}

.has-early-access-container {

    &:hover,
    &:focus {
        .lock-icon {
            display: none;
        }

        .active-lock-icon {
            display: inline;

            image {
                height: rem(16);
                width: rem(16);
            }
        }
    }
}

.early-access-container-tooltip .early-access-tooltip-header {
    font-family: $gotham-bold;
}

.e-gift-card-message {
    font-size: rem(12);
    line-height: rem(16);
    margin-top: rem(8);
}
