// accordion
.accordion-container {
    .card {
        font-family: $gotham-regular;
        font-size: rem(14);
        line-height: rem(20);
        border: none;
        border-radius: 0;
        margin-bottom: 0;
        border-bottom: thin solid $silver;

        .card-header {
            border: none;
            border-radius: 0;
            padding: rem(16);
        }

        .btn {
            position: relative;
        }

        &-body {
            line-height: rem(20);
            padding: rem(8) 0 rem(40) rem(16);

            .content {
                padding: 0;
                margin: 0;
            }
        }

        &-header {
            padding: rem(16);

            h5 {
                font-size: inherit;
                font-weight: normal;
                line-height: rem(1);
            }

            .btn {
                font-size: rem(14);
                padding: 0;
                height: inherit;
                line-height: rem(24);
                letter-spacing: rem(0.35);
                text-decoration: none;
                border: none;
                width: 100%;
                text-align: left;

                &::after {
                    position: absolute;
                    content: '';
                    transform: rotate(45deg);
                    height: rem(12);
                    width: rem(12);
                    border-bottom: rem(1) solid $icon-background-color;
                    border-right: rem(1) solid $icon-background-color;
                    right: 0;
                    top: rem(2);
                    transition: all 0.3s ease;
                }

                &[aria-expanded='true'] {
                    &::after {
                        transform: rotate(225deg);
                        top: rem(9);
                    }
                }
            }
        }
    }

    &:first-child {
        .card {
            border-top: rem(1) solid $silver;
        }
    }
}

.info-icon {
    .tooltip {
        background-color: $dropdown-background-color;
        color: $grey-text;
        font-family: $gotham-regular;
        line-height: rem(16);
        font-size: rem(12);
        border: rem(1) solid $silver;
        padding: rem(16);

        &::after {
            border-top: rem(7) solid $silver;
        }
    }
}

.sticky-top {
    z-index: $zindex-highest;
}

.input-group-append .btn,
.input-group-prepend .btn {
    z-index: 0;
}

.container .breadcrumb {
    border-bottom: 0;
    padding: rem(24) 0 0;
    margin: 0;
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: rem(0.4);
}

.container {
    @include media-breakpoint-only(md) {
        max-width: 100%;
    }

    @include media-breakpoint-only(lg) {
        max-width: 100%;
    }
}

body.modal-open,
.no-scroll {
    -webkit-overflow-scrolling: touch;
}

body.modal-open {
    overflow: hidden !important;
}
