.recommendations {
    margin: rem(40) auto;

    .product-tile {

        .add-to-wish-list,
        .strike-wish-list,
        .loyalty-early-access-locked {
            top: rem(8);
            right: rem(8);
        }

        .image-container {
            min-height: auto;
        }
    }

    .title {
        width: 100%;
        font-family: $gotham-semibold;
        text-transform: uppercase;
        font-size: rem(20);
        line-height: rem(32);
        text-align: center;
        margin-bottom: rem(32);
        padding: 0;
    }

    &-image {
        width: 100%;
    }

    &-products {
        display: flex;
        flex-wrap: nowrap;

        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
        }
    }

    &-products-tile {
        padding: 0 rem(4);

        &.col-6 {
            @include media-breakpoint-down(sm) {
                padding: 0 rem(4);
                flex: 0 0 45%;
                max-width: 45%;
            }
        }
    }

    .content-center {
        justify-content: normal;
    }

    @include media-breakpoint-up(md) {
        .content-center {
            justify-content: center;
        }
    }

    div[id^='cq_recomm_slot'] {
        width: 100%;
    }
}

.product-tile,
.quickview-container {
    .carousel-control-next:focus,
    .carousel-control-prev:focus,
    .tile-img-link {
        &:focus {
            border: rem(2) dotted $mulled-wine;
        }
    }
}

.product-tile {
    .carousel-control-next:focus,
    .carousel-control-prev:focus {
        &:focus {
            height: 25%;
            opacity: 1;
        }
    }
}

.product-listing-button {
    margin-top: rem(24);
    @include media-breakpoint-up(md) {
        margin-top: rem(40);
    }
}
