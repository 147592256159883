.card {
    .gwp-title {
        text-transform: uppercase;
    }

    .line-item-attributes,
    .line-item-option,
    .quantity-value {
        p {
            margin-bottom: 0;
        }
    }

    .line-item-name {
        white-space: nowrap;
        color: $emperor;
        font-family: $crimson;
        font-size: rem(18);
        line-height: rem(24);
        font-weight: normal;
        width: 100%;

        a {
            text-decoration: none;
        }
    }

    .item-image {
        .mini-cart-container & {
            height: auto;
            padding: 0;
        }

        img.product-image {
            max-height: 100%; // override of productCard.scss <- ~base/miniCart <- baseGlobal.scss
        }
    }

    .price {
        word-wrap: nowrap;
        padding: 0 rem(16);
        margin: 0;
        padding-left: 0;
    }

    .line-item-total-price-amount {
        color: $emperor;
        font-family: $gotham-semibold;
        font-weight: normal;
        font-size: rem(14);
        line-height: rem(20);
    }
}

.product-line-item-details {
    overflow-y: hidden;
}

.item-image {
    margin-right: 0;
}

@include media-breakpoint-down(md) {
    .receipt {
        .product-line-item-details {
            .line-item-header {
                >.price {
                    margin-top: rem(4);
                }
            }
            .item-status-container, .product-item-shipping-details {
                .line-item-text, .value {
                    display: block;
                    margin-left: 0;
                }
                .tertiary-link {
                    font-size: inherit;
                }
            }
        }
    }
}
