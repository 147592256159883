// Base font, color setting
body {
    font-family: $gotham-regular;
    font-size: rem(14);
    color: $grey-text;
    line-height: rem(16);
    background-color: $white;
    -webkit-font-smoothing: antialiased;
}

.svg-icon {
    pointer-events: none;
}

button {
    background: none;
    border: 0;
    color: $link-color;
}

a,
button,
.btn {
    &:focus {
        outline: rem(1) dotted $mulled-wine;
        box-shadow: none;
    }
}

.custom-checkbox .custom-control-input:focus + label,
.custom-radio .form-check-input:focus + label {
    outline: rem(2) dotted $mulled-wine;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.veil {
    z-index: $zindex-max-highest;
}

.svg-wishlist-active-icon {
    fill: $lilly-pink;
}
