@import './global/global';
@import './needHelp';
@import './togglePassword';

.page-title.create-account-page-title {
    @include media-breakpoint-up(md) {
        margin: rem(32) 0 rem(40);
    }

    margin: rem(32) 0 rem(16);
}

.early-access-level {
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: rem(0.4);
    margin-top: rem(32);
}

.registration-left {
    @include media-breakpoint-up(md) {
        padding-right: rem(50);
    }

    legend {
        margin-bottom: rem(8);
        font-size: rem(14);
    }

    .birthday-group {
        margin-top: rem(40);
    }

    .birth-month {
        margin-top: rem(0);

        .float-label {
            left: initial;
        }
    }

    .birth-day {
        margin-top: rem(0);

        .float-label {
            left: initial;
        }
    }
}

.registration-right {
    @include media-breakpoint-up(md) {
        padding-left: rem(50);
    }

    .create-account-btn {
        margin-top: rem(40);
    }

    .password-rules-content {
        font-size: rem(12);
        line-height: rem(16);
        margin: rem(16) 0;

        p {
            margin-bottom: 0;
        }
    }

    .register-confirm-password {
        padding-bottom: rem(40);
    }

    .custom-control-label {
        line-height: rem(16);
    }

    .loyalty-program-checkbox {
        .loyalty-program-label {
            line-height: rem(16);
            margin-top: rem(8);

            .loyalty-program-style {
                font-family: $gotham-regular;
                text-decoration: underline;
            }

            .tertiary-link {
                font-size: rem(12);
                line-height: rem(16);
                cursor: pointer;
            }

            .loyalty-checkbox-text {
                line-height: rem(16);
                margin-top: rem(16);

                @include media-breakpoint-up(md) {
                    margin-top: rem(8);
                }
            }
        }
    }

    .sms-optin-email-checkbox {

        .sms-optin-label {
            line-height: rem(24);
        }
    }

    .sms-optin-privacy {
        margin: rem(32) 0;
    }

    .privacy-policy {
        font-size: rem(12);
        line-height: rem(16);

        .tertiary-link {
            font-size: rem(12);
            line-height: rem(16);
        }
    }

    .cancel {
        text-align: center;
        margin-top: rem(16);
    }
}
