.back-to-page-link,
.back-to-account-link {
    font-size: rem(12);
    line-height: rem(16);
    font-family: $gotham-regular;
    margin-top: rem(16);
    color: $breadcrumb-text-color;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-left: rem(16);

    &::after {
        width: rem(12);
        height: rem(12);
        content: '';
        border-top: 1px solid $breadcrumb-border-color;
        border-left: 1px solid $breadcrumb-border-color;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: rem(2);
    }
}
