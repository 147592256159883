.btn {
    font-family: $gotham-semibold;
    font-size: rem(14);
    line-height: rem(48);
    padding: 0;
    border-radius: 0;
    border: rem(1) solid transparent;
    height: rem(48);

    @include media-breakpoint-up(md) {
        line-height: rem(56);
        height: rem(56);
    }

    // custom button - primary
    &.button-primary {
        @include button(
            $bg: $primary-bg-color,
            $color: $primary-color,
            $border-color: $primary-bg-color,
            $hover-bg: $primary-active-bg-color,
            $hover-color: $primary-color,
            $hover-border-color: $primary-active-bg-color,
            $active-bg: $primary-active-bg-color,
            $active-color: $primary-color,
            $active-border-color: $primary-active-bg-color
        );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $primary-disabled-bg-color, $color: $primary-disabled-color, $border-color: $primary-disabled-bg-color);
        }
    }

    // custom button - secondary
    &.button-secondary {
        @include button(
            $bg: $secondary-bg-color,
            $color: $secondary-color,
            $border-color: $secondary-color,
            $hover-bg: $secondary-active-bg-color,
            $hover-color: $secondary-bg-color,
            $hover-border-color: $secondary-active-bg-color,
            $active-bg: $secondary-active-bg-color,
            $active-color: $secondary-bg-color,
            $active-border-color: $secondary-active-bg-color
        );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $secondary-disabled-bg-color, $color: $secondary-disabled-color, $border-color: $secondary-disabled-border-color);
        }
    }

    // button secondary, but default background color is transparent instead of solid
    &.button-secondary-transparent {
        @include button(
            $bg: transparent,
            $color: $secondary-color,
            $border-color: $secondary-color,
            $hover-bg: $secondary-active-bg-color,
            $hover-color: $secondary-bg-color,
            $hover-border-color: $secondary-active-bg-color,
            $active-bg: $secondary-active-bg-color,
            $active-color: $secondary-bg-color,
            $active-border-color: $secondary-active-bg-color
        );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $secondary-disabled-bg-color, $color: $secondary-disabled-color, $border-color: $secondary-disabled-border-color);
        }
    }
}

.tertiary-link {
    color: $grey-text;
    font-family: $gotham-regular;
    font-size: rem(14);
    line-height: 24px;
    text-decoration: underline;
    letter-spacing: rem(0.4);
    border: 0;
    background: $white;
}
