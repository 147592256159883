@import '../product/fitpredictor';
@import '../product/notifyMeDialog';

.quickview-container {
    max-width: rem(952);
    margin: 0 auto;

    .product-quickview.product-detail {
        >button.close {
            margin-top: rem(5);
            margin-right: rem(10);
        }
    }
}

.product-quickview.product-detail {
    margin-top: rem(56);
    margin-bottom: rem(56);
    position: relative;

    >button.close {
        font-size: rem(16);
        position: absolute;
        z-index: $zindex-low;
        top: 0;
        right: 0;
        margin-top: rem(16);
        margin-right: rem(16);
    }

    .close {
        opacity: 1;
    }

    .primary-images {
        padding: rem(48) rem(16) 0 rem(16);

        .add-to-wish-list-container,
        .loyalty-early-access-lock-container {
            position: relative;
        }

        .add-to-wish-list,
        .strike-wish-list,
        .loyalty-early-access-locked {
            right: rem(16);
        }
    }

    .product-quickview-detail {
        position: relative;
        padding: rem(20) rem(20) 0 rem(20);

        .product-name {
            font-family: $crimson;
            font-size: rem(24);
            line-height: rem(32);
            margin-bottom: rem(8);
        }

        .prices {
            text-align: left;
            margin-bottom: rem(16);
            padding-top: 0;
            padding-bottom: 0;

            .sales {
                font-family: $gotham-semibold;
                font-size: rem(14);
                line-height: rem(20);
            }
        }

        .seo-long-description-container {
            .description-display-name {
                margin-top: rem(28);
                padding-bottom: rem(8);
                font-family: $gotham-semibold;
                font-size: rem(14);
                line-height: rem(20);
            }

            ul {
                padding-left: rem(14);

                li {
                    list-style: disc;
                    font-size: smaller;
                }
            }
        }

        .full-pdp-link-section {
            margin-top: rem(16);
            text-align: left;

            .full-pdp-link {
                @extend .tertiary-link;

                font-size: rem(12);
            }
        }

        .notify-me-desc {
            font-family: $gotham-regular;
            font-size: rem(12);
            line-height: rem(16);
            letter-spacing: rem(0.4);
        }

        .prices-add-to-cart-actions {
            padding: 0;
            margin-top: 0;
        }
    }

    .attributes {
        [data-attr='color'] {
            .attribute {
                margin-top: 0;
                max-width: rem(350);
            }
        }

        [data-attr='color'] {
            margin-bottom: rem(16);
        }

        .swatch {
            .text-underline {
                text-decoration: underline;
            }
        }
    }

    .attribute {
        margin-top: 0;

        .selected-swatch-name {
            margin-bottom: rem(8);
        }

        .color-attribute {
            position: relative;
            width: rem(32);
            height: rem(32);
            padding: 0;

            .swatch-circle {
                border: rem(1) solid $swatch-selection-border-color;

                &.selected {
                    width: rem(24);
                    height: rem(24);
                }
            }
        }

        .size-attribute-label-section {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: rem(8);
        }
    }

    .size-attribute-label-section {
        .size-attribute-label {
            font-family: $gotham-semibold;
            font-size: rem(14);
            line-height: rem(20);
        }

        .size-chart {
            margin-top: 0;
            margin-bottom: 0;

            .size-chart-link {
                font-size: rem(12);
            }
        }
    }

    /* Used !important to override fit predictor inline styles */
    #fpRoot {
        margin-bottom: rem(16);

        .fp-top {
            left: rem(92) !important;

            &.fp-custom-tooltip {
                width: rem(180);
                left: -50% !important;
                transform: translate(-50%) !important;
                top: rem(-40) !important;
            }
        }
    }

    .size-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-8);

        .size-list {
            min-width: rem(56);
            height: rem(56);
            text-align: center;
            padding: rem(8);

            .size-btn {
                border: none;
                font-family: $gotham-regular;
                font-size: rem(12);
                line-height: rem(20);
                letter-spacing: normal;
                width: calc(100%);
                height: calc(100%);
                border-radius: 50%;
                padding: rem(8);

                &.not-available {
                    text-decoration: line-through;
                }

                &:not(:disabled) {

                    &:hover,
                    &:focus {
                        background-color: $quick-view-size-btn-hover-background-color;
                        border-radius: 50%;
                    }
                }

                &[disabled] {
                    opacity: 0.35;
                }

                &.selected {
                    border: rem(1) solid $quick-view-size-btn-selected-border-color;
                }
            }
        }
    }

    .carousel {

        .carousel-control-prev,
        .carousel-control-next {
            justify-content: center;

            .icon-prev,
            .icon-next {
                background-color: transparent;
            }
        }

        .carousel-counter {
            font-family: $gotham-regular;
            font-size: rem(12);
            line-height: rem(16);
            letter-spacing: rem(0.4);
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: rem(16);
            margin-bottom: rem(16);
        }

        .carousel-indicators {
            display: none;
        }

        .icon-prev,
        .icon-next {
            &::before {
                content: '';
                position: relative;
                color: $icon-background-color;
                background-color: transparent;
                border-style: solid;
                border-width: rem(1) rem(1) 0 0;
                height: rem(16);
                width: rem(16);
                transform: rotate(-45deg);
                display: inline-block;
            }
        }

        .icon-prev::before {
            transform: rotate(-135deg);
        }

        .icon-next::before {
            transform: rotate(45deg);
        }
    }

    @include media-breakpoint-up(md) {
        .primary-images {
            padding: 0 rem(16);
        }

        .product-quickview-detail {
            padding: rem(32) rem(40) 0 rem(24);
            margin: 0;
            overflow: visible; // Style to make fit predictor wizard open left to the fit predictor link
        }
    }

    .item-unavailable-message {
        font-family: $gotham-semibold;
        font-size: rem(14);
        line-height: rem(20);
        color: $product-unavailable-message-color;
        padding: rem(2) 0;
        margin: rem(16) 0;

        @include media-breakpoint-up(md) {
            margin: rem(24) 0;
        }
    }
}

.quick-view-dialog {
    &.modal-dialog {
        max-width: unset;
        margin: 0;
    }

    >.modal-content {
        border: transparent;

        >.modal-body {
            max-height: none;
            overflow-y: visible;
            padding: 0;
        }

        .product-quickview {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .modal-header {
        background-color: inherit;
    }

    .modal-body {
        max-height: unset;
    }

    @include media-breakpoint-up(md) {
        &.modal-dialog {
            max-width: rem(986);
            margin: rem(8) auto;
        }

        >.modal-content {
            >.modal-body {
                max-height: rem(608);
            }
        }
    }
}

.availability {
    &.product-availability {
        margin: rem(24) 0;

        .availability {
            margin-bottom: 0;
        }

        .availability-msg {
            font-family: $gotham-regular;
            font-size: rem(12);
            letter-spacing: rem(0.4);
            line-height: rem(16);
            margin-bottom: 0;
            color: $error-red;

            .availability-message-text {
                margin-bottom: 0;

                &.select-size-info {
                    font-family: $gotham-regular;
                    color: $error-red;
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin: rem(16) 0;
        }
    }
}

.product-wrapper {
    .availability.product-availability {
        margin: rem(24) rem(-16) rem(8);
        min-height: rem(16);

        @include media-breakpoint-up(md) {
            margin-top: rem(16);
        }
    }

    .prices-add-to-cart-actions {
        margin-top: 0;
    }
}
