@import 'bootstrap/scss/functions';
@import '~base/variables';

//z-index values
$zindex-negative: -1;
$zindex-zero: 0;
$zindex-lowest: 1;
$zindex-low: 2;
$zindex-medium: 3;
$zindex-high: 4;
$zindex-higher: 5;
$zindex-earlyaccess-badge: 15;
$zindex-highest: 999;
$zindex-max-highest: 9999;
$zindex-above-salesfloor: 100000000;

//font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// bootstrap gutter width
$grid-gutter-width: 32px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1362px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1342px
);
