$white: #fff;
$black: #000;
$lilly-pink: #e00083;
$lilly-navy: #19193d;
$monza: #e00024;
$emperor: #4f4f4f;
$boulder: #767676;
$silver: #ccc;
$mercury: #e1e1e1;
$wildsand: #f6f6f6;
$mulled-wine: #474764;
$alto: #cecece;
$dusty-gray: #979797;
$navy-blue: #006;
$rosseau-pink: #ffd1ff;
$anzac: #dca144;
$alabaster: #fafafa;
$hot-pink: #e6007a;
$lola: #e7dde7;
$moonraker: #e5cbf7;
$scorpion: #575757;
$lavender-blush: #ffedf0;
$selago: #faf7fe;
$light-pink: #fcf4f9;
$light-purple: #f0eeff;
$light-grayish-blue: #f4f6fc;
$gallery: #f0f0f0;
$ghostwhite: #f3f3f7;
$magenta-pink: #fcf4f9;
$rustic: #bbb1b1;
$venetian-red: #d0021b;
$whiteish-grey: #eaeaea;
$placebo-purple: #f7f1fd;
$magnolia: #fcfaff;
$pale-lavender: #e5daff;
$whisper: #e7e7e7;
$concrete: #f3f3f3;
$snow: #fff4fa;
$titan-white: #ebe9ff;
$crimson-red: #e30083;
$gainsboro: #d8d8d8;
$cadmium-red: #e00023;
$forest-green: #228b22;

// general variables
$error-red: $monza;
$grey-text: $emperor;
$error-border-color: $monza;
$error-text-color: $monza;
$select-icon-border-color: $emperor;
$link-seperator-border-color: $dusty-gray;
$light-border: $mercury;
$grey-border: $mercury;
$silver-border: $silver;
$active-border: $emperor;
$icon-bg-color: $emperor;
$tertiary-link-underline-color: $emperor;
$page-title-background-color: $white;
$page-title-color: $emperor;
$selectbox-border: $silver;
$base-text-color: $emperor;
$dropdown-background-color: $white;
$dropdown-hover-background-color: $mercury;
$icon-background-color: $emperor;
$base-border-color: $emperor;
$checkbox-border-color: $emperor;
$input-button-border-color: $emperor;
$unselected-input-border-color: $silver;
$down-arrow-border-color: $lilly-pink;
$active-background-color: $wildsand;
$caret-scroll-bg-color: rgba($white, 0.85);
$outline-color: $mulled-wine;
$back-to-top-bg-color: rgba($white, 0.85);
$error-page-background: $selago;
$border-bottom-nav-tab: $rustic;
$selected-nav-tab: $lilly-pink;
$unselected-nav-tab: $gallery;
$save-for-later-sale-price: $venetian-red;
$page-navigation-text-link-color: $lilly-pink;
$carousel-navigation-button-shadow: $silver;

// search
$search-border-color: $silver;
$search-input-text-color: $boulder;
$search-text-color: $emperor;
$no-search-text-color: $emperor;
$search-menu-background-color: $white;
$sort-btn-text-color: $emperor;

// filters
$filters-text-color: $emperor;
$selected-filter-button-background-color: $emperor;
$filter-swatch-border-color: $silver;
$filter-text-input-placeholder-color: $boulder;
$price-filter-button-color: $white;

// monogram
$monogram-selection-blue: $navy-blue;
$monogram-selection-white: $wildsand;
$monogram-selection-pink: $rosseau-pink;
$monogram-selection-gold: $anzac;
$monogram-selection-border-color: $silver;
$monogram-arrow-color: $emperor;

// sizechart colors
$scrollbar-pink: $lilly-pink;
$sizechart-menu-bg: $white;
$table-row-grey: $wildsand;
$table-row-white: $white;
$active-border-bottom-navy: $lilly-navy;
$accordion-border-silver: $silver;

// custom buttons colors
$primary-bg-color: $lilly-navy;
$primary-color: $white;
$primary-active-bg-color: $mulled-wine;
$primary-disabled-color: $scorpion;
$primary-disabled-bg-color: $alto;

$secondary-bg-color: $white;
$secondary-color: $lilly-navy;
$secondary-active-bg-color: $lilly-navy;
$secondary-disabled-color: $boulder;
$secondary-disabled-bg-color: $white;
$secondary-disabled-border-color: $silver;

$light-modal-background-color: rgba(255, 255, 255, 0.8);

// Product colors
$price-color: $emperor;
$sale-price-color: $lilly-pink;
$percent-off-color: $emperor;
$selecte-swatch-border-color: $lilly-navy;
$sale-swatch-indicator-color: $lilly-pink;
$product-tile-background-color: $white;
$product-unavailable-message-color: $monza;
$swatch-selection-border-color: $silver;
$sale-message-color: $monza;
$add-to-cart-message-background: $white;
$add-to-cart-message-color: $emperor;
$swatch-neon-color: $lilly-pink;
$approaching-discount-color: $lilly-pink;

// Cart & Checkout
$divider-color: $mercury;
$seperator-color: $dusty-gray;
$link-color: $emperor;
$card-border-color: $silver;
$card-header-color: $boulder;
$promo-code-border-color: $silver;
$product-total-price-color: $emperor;
$gwp-title-border-color: $silver;
$cart-error: $error-red;
$out-of-stock-color: $error-red;
$coupon-code-color: $lilly-pink;
$product-name-color: $link-color;
$checkout-login-title-border: $lilly-navy;
$size-label-color: $boulder;
$bonus-product-border-color: $silver;
$checkout-button-background-color: $white;
$help-text-color: $emperor;
$order-total-border-color: $silver;
$checkout-total-text-color: $emperor;
$check-mark-color: $lilly-pink;
$receipt-header-border-color: $lilly-pink;
$order-detail-background-color: $white;
$checkout-summary-title-border-color: $silver;
$checkout-confirmation-summary-title-color: $lilly-navy;
$apple-pay-button-color: $white;
$checkout-title-color: $lilly-navy;
$checkout-login-card-heading-border-color: $lilly-pink;
$checkout-line-item-attribute-color: $boulder;
$checkout-line-item-text-color: $emperor;
$cart-gwp-message-color: $lilly-pink;
$apply-promo-color: $white;
$billing-address-border-color: $silver;
$availability-error-message-color: $monza;
$checkout-error-border-color: $monza;
$checkout-error-heading-color: $lilly-navy;
$checkout-error-text-color: $emperor;
$checkout-error-background: $lavender-blush;
$gwp-updated-border-color: $monza;
$gwp-updated-background-color: $wildsand;
$order-detail-return-banner-color: $wildsand;
$e-gift-card-noneturnable-bg: $wildsand;
$cart-error-background-color: $lavender-blush;

// Shipment
$info-icon-border: $emperor;
$active-info-icon-bg: $boulder;

// Headings
$heading-pink: $lilly-pink;

// Pick up in store
$store-results-heading: $lilly-pink;
$stock-available: $lilly-pink;
$geolocation-error-color: $monza;

// form elements
$form-control-border-color: $silver;
$form-control-text-color: $emperor;
$form-control-focused-border-color: $emperor;
$custom-control-text-color: $white;
$custom-control-border-color: $boulder;
$custom-control-background-color: $white;
$custom-control-inactive-border-color: $white;
$custom-control-inactive-background-color: $boulder;
$form-control-focused-color: $boulder;
$custom-checkbox-background-color: $boulder;
$custom-checkbox-border-color: $white;

// quick view
$quick-view-button-background-color: $white;
$quick-view-button-border-color: $boulder;
$quick-view-text-color: $lilly-navy;
$quick-view-error-color: $monza;
$quick-view-size-btn-hover-background-color: $wildsand;
$quick-view-size-btn-selected-border-color: $emperor;

// footer
$footer-backgroud-color: $white;
$footer-border-color: $lilly-pink;
$footer-title-color: $emperor;

// Gift card
$gift-card-value-color: $lilly-pink;
$gift-card-error-color: $error-red;
$gift-card-image-background-color: $alabaster;
$gift-card-denomination-hover-background-color: $wildsand;
$gift-card-denomination-selected-border-color: $emperor;
$gift-card-currency-symbol: $emperor;
$gift-card-custom-amount-placeholder-color: $boulder;
$cart-gift-card-value-color: $emperor;
$gift-card-banner-background-color: $gallery;

//homepage
$home-column-heading-color: $hot-pink;
$home-column-btn-border-color: $lilly-navy;
$home-column-btn-link-color: $lilly-navy;
$home-column-content-color: $emperor;
$home-banner-title-color: $emperor;
$home-banner-cta-color: $emperor;
$home-shop-btn-border-color: $white;
$home-shop-text-color: $white;
$home-family-text-color: $lilly-navy;
$home-family-btn-border-color: $lilly-navy;

//account
$account-cards-header-color: $lilly-navy;
$account-card-border-color: $lilly-navy;
$emptycard-background-color: $wildsand;
$need-help-border-color: $silver;
$account-card-hint-text: $boulder;
$account-payment-border-color: $silver;
$account-cards-selector-border-color: $silver;
$account-cards-selector-arrow-color: $silver;

// Header - Mega Menu
$header-text-color: $emperor;
$header-border-color: $lilly-pink;
$header-background-color: $white;
$header-banner-background-color: $lilly-navy;
$header-focus-background-color: $wildsand;
$header-outline-color: $silver;
$header-highlighted-color: $lilly-pink;
$stripe-icon-color: $white;
$stripe-text-color: $white;

//Category Landing Page
$clp-text-color: $emperor;
$clp-tab-border-color: $lilly-pink;

// Store locator
$store-locator-phone: $emperor;
$store-locator-border-color: $silver;
$store-locator-dot-color: $lilly-pink;
$stores-count-message-color: $lilly-pink;

//Wishlist
$wishlist-product-attr-label-color: $boulder;
$wishlist-items-separator-color: $lilly-pink;
$wishlist-item-availability-message-color: $monza;
$wishlist-item-availability-message-bg-color: $wildsand;
$wishlist-search-error-color: $monza;

//Login
$login-banner-bg: $mercury;
$login-banner-fg: $lilly-navy;

//My Account - Address Book
$address-action-link-separator: $boulder;
$address-card-border: $silver;
$wishlist-results-table-even-background-color: $wildsand;

//contact us
$contact-us-border-color: $silver;
$customer-service-active-link-arrow-color: $emperor;

// video-component
$video-component-title-color: $emperor;

// Forgot Password
$forgot-password-header-text-color: $emperor;

// spinner
$spinner-background-color: $silver;

// order history
$order-history-dropdown-item-background-color: $wildsand;
$order-history-dropdown-item-color: $grey-text;

//order details
$order-details-adjusted-total-color: $lilly-pink;

// spinner
$spinner-background-color: $silver;

// shop by print
$sbp-quickview-bg-color: $white;
$sbp-drawer-bg-color: $white;
$sbp-quickview-underline: $lilly-pink;
$sbp-drawer-box-shadow-color: rgba(147, 153, 178, 0.54);
$sbp-mini-swatch-text-color: $lilly-navy;
$sbp-header-navy-color: $lilly-navy;
$sbp-header-white-color: $white;
$sbp-header-grey-color: $emperor;

// breadcrumb
$breadcrumb-text-color: $emperor;
$breadcrumb-border-color: $emperor;

//ratings&reviews
$ratings-filter-border-color: $silver;
$ratings-stars-color: $lilly-navy;
$slider-bar-background-color: $silver;

// autocomplete
$suggestion-bg-color: $dropdown-background-color;
$suggestion-selected-bg-color: $active-background-color;

//Pickup Store delivery message PDP
$instore-pickup-message-color: $boulder;

// Switch component
$switch-border-color: $silver;
$switch-bg-color: $white;
$switch-on-bg-color: $lilly-pink;
$switch-off-bg-color: $silver;

// Alerts
$alert-info-background-color: $light-pink;
$alert-info-border-color: $lavender-blush;
$alert-info-text-color: $emperor;

// Loyalty
$loyalty-background-color: $magenta-pink;
$loyalty-swatch-selected-color: $crimson-red;
$loyalty-swatch-border-color: $gainsboro;
$loyalty-faq-border-color: $lilly-navy;
$loyalty-benefit-title-color: $lilly-navy;
$loyalty-faq-content-color: $emperor;
$loyalty-faq-card-border-color: $silver;
$loyalty-faq-card-bg-color: $white;

//bazaarvoice
$bv-navy-color: $lilly-navy;
$bv-text-color: $emperor;
$bv-border-color: $whiteish-grey;
$bv-page-background-color: $white;
$bv-selected-button-color: $forest-green;
$bv-unselected-button-color: $cadmium-red;
$bv-review-modal-button-text: $white;
$bv-star-color: $lilly-navy;
$bv-button-background-color: $lilly-navy;
$bv-button-border-color: $lilly-navy;

$loyalty-service-error-bg-color: rgba(246, 246, 246, 0.5);
$loyalty-heading-color: $lilly-navy;
$loyalty-referral-background-color: $light-grayish-blue;
$loyalty-referral-color: $emperor;
$loyalty-dashboard-rewards-bg-color: $placebo-purple;
$loyalty-dashboard-rewards-text-color: $emperor;
$loyalty-dashboard-points-bg-color: $magnolia;
$loyalty-dashboard-pointer-empty-color: $pale-lavender;
$loyalty-dashboard-pointer-shadow: $whisper;
$loyalty-dashboard-error-background: $wildsand;
$loyalty-activity-dropdown-arrow-color: $emperor;
$loyalty-activity-text-color: $emperor;
$loyalty-activity-dropdown-selected-bg-color: $wildsand;
$loyalty-activity-divider-line: $emperor;
$loyalty-activity-primary-background: $wildsand;
$loyalty-activity-secondary-background: $titan-white;
$loyalty-activity-tertiary-background: $snow;
$loyalty-order-details-error: $cadmium-red;
$loyalty-order-details-points-text: $crimson-red;
$loyalty-heading-color: $lilly-navy;
$loyalty-points-earned-this-year-color: $lilly-navy;
$loyalty-points-earned-this-year-text-color: $emperor;
$loyalty-account-view-dashboard-color: $emperor;
$loyalty-benefit-lock-color: rgba(25, 25, 61, 0.5);
$loyalty-dashboard-header-border-color: $lilly-navy;
$loyalty-border-color: $lilly-navy;
$loyalty-voucher-card-background-color: $alabaster;
$loyalty-benefits-scrollbar-color: $lilly-navy;
$loyalty-birthdaybanner-bg-color: $lilly-navy;
$loyalty-badge-background-color: $lilly-navy;
$loyalty-badge-text-color: $white;
$loyalty-new-tag-color: $lilly-navy;
$loyalty-guest-new-tag-color: $lilly-navy;
$loyalty-vouchers-scrollbar-color: $dusty-gray;
$loyalty-vertical-scrollbar-color: $lilly-navy;
$loyalty-enroll-background-color: $white;

//Stylitics
$stylitics-jumplink-bg-color: $white;
$stylitics-jumplink-border-color: $boulder;
$stylitics-jumplink-text-color: $emperor;
$stylitics-text-hover-color: $boulder;
$stylitics-badge-border-color: $boulder;
$stylitics-badge-background-color: $wildsand;
$stylitics-default-bundle-border-color: $grey3;
$stylitics-active-bundle-border-color: $boulder;
$stylitics-plp-border: $silver;

//Bolt
$bolt-card-bg-color: $wildsand;
$bolt-card-content-text: $emperor;
$bolt-button-border-color: $lilly-navy;

//Gift card on cart
$gift-card-in-cart-background-color: $wildsand;
$gift-card-in-cart-border-color: $silver;
$quick-gift-cart-btn-text-color: $emperor;
$gift-card-selected-amount-background: $white;
$quick-gift-card-add-to-cart-button-color: $lilly-navy;

//Notify me box
$notifyme-box-bg-color: $wildsand;
$notifyme-box-text-color: $emperor;
$notifyme-box-text-color-accent: $lilly-navy;
$notifyme-box-border-color: $silver;

//Blog
$blog-hub-post-color: $boulder;
$blog-hub-post-title-color: $emperor;
$blog-hub-post-image-bg-color: $mercury;
$blog-hub-banner-color: $white;
$blog-entry-date-color: $boulder;
$blog-entry-title-color: $emperor;
$blog-pagination-select-border-color: $silver-border;
$blog-scroll-bg-color: $wildsand;
$blog-scroll-fg-color: $boulder;
