.notify-me-container {
	color: $emperor;
	font-family: $gotham-regular;
	font-size: rem(14);
	line-height: rem(20);
	margin-top: rem(24);

	a {
		text-decoration: underline;
	}

	.notify-img {
		margin-bottom: rem(40);
		text-align: center;
	}

	.notify-product-desc {}

	.notify-product-name {
		font-family: $crimson;
		font-size: rem(24);
		line-height: rem(32);
		margin-bottom: rem(16);
	}

	.notify-me-desc {
		margin: rem(24) 0 rem(16);
	}

	.notify-price {
		font-family: $gotham-semibold;
		font-size: rem(16);
		line-height: rem(24);
		margin-bottom: rem(4);
	}

	.notify-attributes {
		th {
			font-weight: normal;
			padding-right: rem(8);
		}
	}

	.notify-small-print {
		font-size: rem(12);
		margin: rem(32) 0 rem(16);
	}

	.input-group {
		margin-top: rem(16);
		padding: rem(12) 0 0;

		@include media-breakpoint-up(md) {
			margin-top: rem(48);
		}

		.notify-email {
			background: transparent;
			border: 0;
			border-bottom: 1px solid $boulder;
			border-radius: 0;
			color: $emperor;
			font-size: rem(16);
			line-height: rem(20);
			outline: 0;
			padding: rem(7) 0 0;
			transition: border-color 0.2s;
			width: 100%;

			@include media-breakpoint-up(md) {
				font-size: rem(14);
			}

			&::placeholder {
				color: transparent;
			}

			&:placeholder-shown ~ .notify-email-label {
				cursor: text;
				font-size: rem(14);
				line-height: rem(20);
				top: rem(20);
			}

			~ .notify-email-label,
			&:focus ~ .notify-email-label {
				color: $boulder;
				display: block;
				font-size: rem(10);
				line-height: rem(16);
				position: absolute;
				top: 0;
				transition: 0.2s;
			}

			&:focus ~ .notify-email-label {
				color: $emperor;
			}

			&:focus {
				border-bottom: 1px solid $emperor;
			}

			&.text-input-error {
				border-color: $error-red;
				color: $error-red;
				font-family: $gotham-regular;
			}
		}
	}

	.email-error {
		color: $error-red;
		font-family: $gotham-regular;
		font-size: rem(10);
		line-height: rem(16);
		margin-top: rem(8);
	}

	.checkbox-group,
	.notify-me-submit {
		margin-top: rem(24);
	}

	.checkbox-group {
		padding-left: rem(24);
		position: relative;

		.notify-checkbox-label {
			font-size: rem(12);
			letter-spacing: rem(0.4);
			margin-bottom: 0;
		}
	}

	.notify-me-success {
		&-message {
			font-size: rem(12);
			letter-spacing: rem(0.4);
			line-height: rem(16);
			margin-top: rem(16);
		}

		&-actions {
			margin-top: rem(40);
			text-align: center;
		}
	}
}
