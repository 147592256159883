.scrollable-container {
    position: relative;

    .scrollable-content {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        @extend .lp-scrollbar;
        @extend .no-vertical-scroll;
    }

    .scroll-left-arrow,
    .scroll-right-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        margin-top: rem(-30);
        margin-bottom: auto;
        transition: opacity 0.3s;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%);

        &.lp-icon-chevron {
            &::before {
                width: rem(8);
                height: rem(8);
            }
        }
    }

    .scroll-left-arrow {
        left: rem(30);

        &.lp-icon-chevron {
            &::before {
                left: rem(2);
            }
        }
    }

    .scroll-right-arrow {
        right: rem(30);

        &.lp-icon-chevron {
            &::before {
                right: rem(2);
            }
        }
    }

    @include media-breakpoint-up(md) {
        .scrollable-content {

            ~.scroll-left-arrow,
            ~.scroll-right-arrow {
                opacity: 1;
            }
        }

        .scroll-left-arrow,
        .scroll-right-arrow {
            opacity: 1;
        }
    }

    .grid-tile {
        @include media-breakpoint-down(sm) {
            padding: 0 rem(4);
            flex: 0 0 45%;
            max-width: 45%;
        }
    }
}

@include media-breakpoint-up(md) {

    .scrollable-container {

        .scroll-left-arrow,
        .scroll-right-arrow {

            &.lp-icon-chevron {
                &::before {
                    width: rem(16);
                    height: rem(16);
                }
            }
        }

        .scroll-right-arrow {
            right: rem(4);
        }

        .scroll-left-arrow {
            left: rem(4);
        }
    }
    .shop-by-print {
        .scrollable-content {
            padding-left: rem(16);
            padding-right: rem(16);
        }
    }

    .cart-container .recommendations,
    .product-listing,
    .recently-viewed {

        .scroll-left-arrow,
        .scroll-right-arrow {
            margin-top: rem(-56);
        }

        .scroll-left-arrow {
            left: rem(-15);
        }

        .scroll-right-arrow {
            right: rem(-15);
        }

    }

    .homepage {

        .scroll-left-arrow,
        .scroll-right-arrow {
            margin-top: rem(-20);
        }
    }

    .ways-to-wear .sbs-carousel {

        .banner {
            padding-right: rem(16);
        }

        .scroll-left-arrow {
            left: rem(4);
        }

        .scroll-right-arrow {
            right: rem(4);
        }

    }

    .product-listing {

        .lp-icon-chevron.double:after,
        .lp-icon-chevron:before {
            height: rem(8);
            width: rem(8);
        }

        .carousel-control {
            height: rem(32);
            width: rem(32);
            background-color: $caret-scroll-bg-color;
            text-align: center;
            border-radius: 100%;
            transition: opacity 0.3s;
            margin: auto rem(16);
            box-shadow: 0 0 rem(5) rem(1) $carousel-navigation-button-shadow;

            &-prev {
                padding-left: rem(4);
            }

            &-next {
                padding-right: rem(4);
            }
        }

        .product-tile {

            .carousel-control-next:focus:focus,
            .carousel-control-prev:focus:focus {
                height: rem(32);
                opacity: 1;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .product-listing {
        .scroll-left-arrow {
            left: rem(-20);
        }

        .scroll-right-arrow {
            right: rem(-20);
        }
    }
}