@font-face {
    font-family: 'Crimson Text Regular';
    src:
 url('../../fonts/CrimsonText-Regular.woff2') format('woff2'),
        url('../../fonts/CrimsonText-Regular.woff') format('woff'),
        url('../../fonts/CrimsonText-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Crimson Text Regular Italic';
    src:
 url('../../fonts/CrimsonText-RegularItalic.woff2') format('woff2'),
        url('../../fonts/CrimsonText-RegularItalic.woff') format('woff'),
        url('../../fonts/CrimsonText-RegularItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Crimson Text Semi Bold';
    src:
 url('../../fonts/CrimsonText-SemiBold.woff2') format('woff2'),
        url('../../fonts/CrimsonText-SemiBold.woff') format('woff'),
        url('../../fonts/CrimsonText-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Crimson Text Semi Bold Italic';
    src:
 url('../../fonts/CrimsonText-SemiBoldItalic.woff2') format('woff2'),
        url('../../fonts/CrimsonText-SemiBoldItalic.woff') format('woff'),
        url('../../fonts/CrimsonText-SemiBoldItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Crimson Text Bold';
    src:
 url('../../fonts/CrimsonText-Bold.woff2') format('woff2'),
        url('../../fonts/CrimsonText-Bold.woff') format('woff'),
        url('../../fonts/CrimsonText-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Crimson Text Semibold Italic';
    src:
 url('../../fonts/CrimsonText-SemiBoldItalic.woff2') format('woff2'),
        url('../../fonts/CrimsonText-SemiBoldItalic.woff') format('woff'),
        url('../../fonts/CrimsonText-SemiBoldItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Crimson Text Bold Italic';
    src:
 url('../../fonts/CrimsonText-BoldItalic.woff2') format('woff2'),
        url('../../fonts/CrimsonText-BoldItalic.woff') format('woff'),
        url('../../fonts/CrimsonText-BoldItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Gotham Light';
    src:
 url('../../fonts/GothamHTF-Light.woff2') format('woff2'),
        url('../../fonts/GothamHTF-Light.woff') format('woff'),
        url('../../fonts/GothamHTF-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Gotham Book';
    src:
 url('../../fonts/GothamHTF-Book.woff2') format('woff2'),
        url('../../fonts/GothamHTF-Book.woff') format('woff'),
        url('../../fonts/GothamHTF-Book.ttf') format('ttf');
}

@font-face {
    font-family: 'Gotham Book Italic';
    src:
 url('../../fonts/GothamHTF-BookItalic.woff2') format('woff2'),
        url('../../fonts/GothamHTF-BookItalic.woff') format('woff'),
        url('../../fonts/GothamHTF-BookItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Gotham Medium';
    src:
 url('../../fonts/GothamHTF-Medium.woff2') format('woff2'),
        url('../../fonts/GothamHTF-Medium.woff') format('woff'),
        url('../../fonts/GothamHTF-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'Gotham Bold';
    src:
 url('../../fonts/GothamHTF-Bold.woff2') format('woff2'),
        url('../../fonts/GothamHTF-Bold.woff') format('woff'),
        url('../../fonts/GothamHTF-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'text-security-disc';
    src: url('../../fonts/text-security-disc.eot');
    src: url('../../fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/text-security-disc.woff2') format('woff2'),
        url('../../fonts/text-security-disc.woff') format('woff'),
        url('../../fonts/text-security-disc.ttf') format('truetype'),
        url('../../fonts/text-security-disc.svg#text-security') format('svg');
}

@mixin sprig-font-face($font-family, $font-weight, $font-style, $file-path) {
    @font-face {
        font-family: '#{$font-family}';
        src: url('#{$file-path}.woff2') format('woff2'),
            url('#{$file-path}.otf') format('opentype');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

// Sprig
@include sprig-font-face('Sprig', 100, normal, '../../fonts/Sprig-Hairline');
@include sprig-font-face('Sprig', 100, italic, '../../fonts/Sprig-HairlineItalic');
@include sprig-font-face('Sprig', 200, normal, '../../fonts/Sprig-Thin');
@include sprig-font-face('Sprig', 200, italic, '../../fonts/Sprig-ThinItalic');
@include sprig-font-face('Sprig', 300, normal, '../../fonts/Sprig-Light');
@include sprig-font-face('Sprig', 300, italic, '../../fonts/Sprig-LightItalic');
@include sprig-font-face('Sprig', normal, normal, '../../fonts/Sprig-Regular');
@include sprig-font-face('Sprig', normal, italic, '../../fonts/Sprig-RegularItalic');
@include sprig-font-face('Sprig', 500, normal, '../../fonts/Sprig-Medium');
@include sprig-font-face('Sprig', 500, italic, '../../fonts/Sprig-MediumItalic');
@include sprig-font-face('Sprig', bold, normal, '../../fonts/Sprig-Bold');
@include sprig-font-face('Sprig', bold, italic, '../../fonts/Sprig-BoldItalic');
@include sprig-font-face('Sprig', 800, normal, '../../fonts/Sprig-Black');
@include sprig-font-face('Sprig', 800, italic, '../../fonts/Sprig-BlackItalic');
@include sprig-font-face('Sprig', 900, normal, '../../fonts/Sprig-Super');
@include sprig-font-face('Sprig', 900, italic, '../../fonts/Sprig-SuperItalic');

// SprigSans
@include sprig-font-face('SprigSans', 100, normal, '../../fonts/SprigSans-Hairline');
@include sprig-font-face('SprigSans', 100, italic, '../../fonts/SprigSans-HairlineItalic');
@include sprig-font-face('SprigSans', 200, normal, '../../fonts/SprigSans-Thin');
@include sprig-font-face('SprigSans', 200, italic, '../../fonts/SprigSans-ThinItalic');
@include sprig-font-face('SprigSans', 300, normal, '../../fonts/SprigSans-Light');
@include sprig-font-face('SprigSans', 300, italic, '../../fonts/SprigSans-LightItalic');
@include sprig-font-face('SprigSans', normal, normal, '../../fonts/SprigSans-Regular');
@include sprig-font-face('SprigSans', normal, italic, '../../fonts/SprigSans-RegularItalic');
@include sprig-font-face('SprigSans', 500, normal, '../../fonts/SprigSans-Medium');
@include sprig-font-face('SprigSans', 500, italic, '../../fonts/SprigSans-MediumItalic');
@include sprig-font-face('SprigSans', bold, normal, '../../fonts/SprigSans-Bold');
@include sprig-font-face('SprigSans', bold, italic, '../../fonts/SprigSans-BoldItalic');
@include sprig-font-face('SprigSans', 800, normal, '../../fonts/SprigSans-Black');
@include sprig-font-face('SprigSans', 800, italic, '../../fonts/SprigSans-BlackItalic');
@include sprig-font-face('SprigSans', 900, normal, '../../fonts/SprigSans-Super');
@include sprig-font-face('SprigSans', 900, italic, '../../fonts/SprigSans-SuperItalic');

//
// Font Stacks
//
$crimson: 'Crimson Text Regular', serif;
$crimson-semibold: 'Crimson Text Semi Bold', serif;
$crimson-bold: 'Crimson Text Bold', serif;
$crimson-italic: 'Crimson Text Regular Italic', serif;
$crimson-italic-semibold: 'Crimson Text Semibold Italic', serif;
$crimson-italic-bold: 'Crimson Text Bold Italic', serif;
$gotham-light: 'Gotham Light', Arial, sans-serif;
$gotham-regular: 'Gotham Book', Arial, sans-serif;
$gotham-italic: 'Gotham Book Italic', Arial, sans-serif;
$gotham-semibold: 'Gotham Medium', Arial, sans-serif;
$gotham-bold: 'Gotham Bold', Arial, sans-serif;
$text-security-disc: 'text-security-disc';

// Sprig Font Variables & Mixins
$sprig: 'Sprig', serif;
$sprigsans: 'SprigSans', sans-serif;

@mixin font-sprig-hairline {
  font-family: $sprig;
  font-weight: 100;
}

@mixin font-sprig-thin {
  font-family: $sprig;
  font-weight: 200;
}

@mixin font-sprig-light {
  font-family: $sprig;
  font-weight: 300;
}

@mixin font-sprig-regular {
  font-family: $sprig;
  font-weight: normal;
}

@mixin font-sprig-medium {
  font-family: $sprig;
  font-weight: 500;
}

@mixin font-sprig-bold {
  font-family: $sprig;
  font-weight: bold;
}

@mixin font-sprig-black {
  font-family: $sprig;
  font-weight: 800;
}

@mixin font-sprig-super {
  font-family: $sprig;
  font-weight: 900;
}

@mixin font-sprigsans-hairline {
  font-family: $sprigsans;
  font-weight: 100;
}

@mixin font-sprigsans-thin {
  font-family: $sprigsans;
  font-weight: 200;
}

@mixin font-sprigsans-light {
  font-family: $sprigsans;
  font-weight: 300;
}

@mixin font-sprigsans-regular {
  font-family: $sprigsans;
  font-weight: normal;
}

@mixin font-sprigsans-medium {
  font-family: $sprigsans;
  font-weight: 500;
}

@mixin font-sprigsans-bold {
  font-family: $sprigsans;
  font-weight: bold;
}

@mixin font-sprigsans-black {
  font-family: $sprigsans;
  font-weight: 800;
}

@mixin font-sprigsans-super {
  font-family: $sprigsans;
  font-weight: 900;
}

