.search-global {
    position: relative;
    padding-bottom: rem(40);

    @include media-breakpoint-up(sm) {
        padding-bottom: 0;
    }

    .search-form {
        &-submit {
            width: rem(20);
            position: absolute;
            padding: 0;
            left: 0;
            top: 0;
            bottom: 0;
            line-height: rem(48);
            height: rem(48);
        }

        &-input {
            display: flex;
            border-bottom: rem(1) solid $search-border-color;

            &:focus-within {
                border-color: $form-control-focused-border-color;
            }
        }

        &-clear {
            width: auto;
            padding: 0;
            line-height: rem(48);
            height: rem(48);
            color: $search-text-color;
            text-decoration: underline;
            letter-spacing: rem(0.4);

            @include media-breakpoint-up(md) {
                width: rem(16);
                text-decoration: none;
            }
        }

        &-close {
            width: rem(16);
            height: rem(16);
            padding: 0;
            margin-bottom: rem(24);
        }
    }

    input {
        border: none;
        border-radius: 0;
        font-family: $gotham-light;
        font-size: rem(24);
        line-height: rem(40);
        color: $search-input-text-color;
        padding-left: rem(38);

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.search-menu {
    position: absolute;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s;
    z-index: $zindex-lowest;
    top: 0;

    @include media-breakpoint-up(lg) {
        top: unset;
    }

    .search-form-input {
        width: 100%;
    }

    &-container {
        padding-top: rem(16);
        padding-bottom: 100%;
        background-color: $search-menu-background-color;
        height: 100vh;
        @include media-breakpoint-up(md) {
            padding: rem(40) 0 rem(64);
            height: auto;
        }
    }

    .fade-bg {
        top: unset;
        height: 100vh;
        position: relative;
    }

    &.header-search-type {
        max-height: 0;

        .search-form-input {
            @include media-breakpoint-up(md) {
                width: 75%;
            }
        }

        .search-global {
            display: none;
        }
    }

    &.open {
        max-height: 100vh;
        display: table;
        width: 100%;

        .search-global {
            display: block;
        }
    }
}

.suggestions {
    position: relative;
    width: 100%;
    border: 0;
    font-size: rem(12);
    line-height: rem(16);
    text-align: left;

    &-section {
        padding-top: rem(16);
        padding-bottom: rem(16);

        &-title {
            padding-bottom: rem(16);
            font-family: $gotham-semibold;
            letter-spacing: rem(0.4);
            @include media-breakpoint-down(sm) {
                padding-bottom: rem(8);
            }

            @include media-breakpoint-up(md) {
                margin-left: rem(8);
            }
        }

        &-items {
            font-family: $gotham-regular;
        }
    }

    &-search-str {
        letter-spacing: rem(0.4);

        .item {
            padding-bottom: rem(8);
        }
    }

    &-related-products {
        .suggestions-section-items .row {
            padding-left: rem(12);
            padding-right: rem(12);
        }

        .col-3 {
            padding-left: rem(4);
            padding-right: rem(4);
        }

        a {
            span {
                display: block;
            }
        }
    }

    &-category-links {
        letter-spacing: rem(0.4);
        display: table;
    }

    .category-wrap {
        justify-content: start;
        @include media-breakpoint-up(md) {
            justify-content: center;
        }
    }

    .item {
        padding: rem(8);
    }

    .category-wrap.category-wrap-mobile {
        @include media-breakpoint-down(sm) {
            padding-left: 0;

            .suggestions-section {
                padding-bottom: rem(16);
            }
        }
    }

    .product-tile .tile-body {
        padding-bottom: rem(8);
        padding-top: 0;
    }
}

.suggestions-section-items .item.product-tile {
    @include media-breakpoint-down(sm) {
        padding: 0 rem(4);
    }
}

.suggestions-section-items .pdp-link span {
    padding-top: rem(8);
}

.search-menu-container-scrollable {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
}
.row.suggestions-section.suggestions-related-products {
    @include media-breakpoint-down(sm) {
        padding-bottom: rem(40);
    }
}
.plp-style {
    z-index: $zindex-lowest;

    .suggestions-wrapper {
        box-shadow: 0 rem(10) rem(15) 0 rgba(0, 0, 0, 0.12);
        position: absolute;
        width: 100%;
    }

    .suggestions-related-products {
        display: none;
    }

    .suggestions {
        padding: 0 rem(40) rem(24);

        .left-container,
        .right-container {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
.left-container {
    .row.suggestions-section.suggestions-search-str {
        @include media-breakpoint-down(sm) {
            padding-top: rem(32);
        }
    }
}
.suggestions-section-items {
    .item.term.search-suggestions-item,
    .item.category-suggestions-name {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
