.product-detail {
    .attributes {
        #fpRoot {
            .fp-root {
                .fp-app-container.fp-app {
                    .fp-element {
                        display: flex;
                        justify-content: space-between;
                        margin: rem(16) 0 rem(24) 0;
                        padding-top: 0;
                        font-size: rem(14);
                        letter-spacing: rem(0.4);

                        span.fp-brand-wr.fp-row {
                            span.fp-i-logo {
                                width: rem(24);
                                height: rem(24);
                            }

                            span.fp-logo-text {
                                span {
                                    line-height: rem(20);
                                    font-weight: $font-weight-medium;
                                    font-family: $gotham-semibold;
                                    color: $emperor;
                                    text-transform: capitalize;
                                }
                            }
                        }

                        span.fp-info-wr {
                            display: flex;
                            align-items: flex-end;
                            line-height: rem(24);

                            span.fp-size-rec {
                                font-family: $gotham-regular;
                                font-weight: $font-weight-light;
                                color: $emperor;
                                text-decoration: underline;
                            }
                        }
                    }

                    .fp-accordion {
                        .fp-arrow {
                            left: rem(20);
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .product-detail {
        .attributes {
            #fpRoot {
                .fp-root {
                    .fp-app-container.fp-app {
                        .fp-element {
                            justify-content: flex-start;
                            margin: rem(16) 0;

                            span.fp-brand-wr.fp-row {
                                margin-right: rem(8);
                            }

                            span.fp-info-wr {
                                font-size: rem(12);
                                line-height: rem(20);
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-set-items {
        .fp-popover {
            left: rem(
                80
            ) !important; // To override inline style of secret sauce fit predictor
        }
    }
}
