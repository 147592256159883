.form-group.custom,
.input-group.custom {
    position: relative;
    margin-top: rem(16);
    margin-bottom: 0;

    .form-control {
        display: block;
        height: rem(48);
        border: none;
        border-radius: 0;
        font-size: rem(16);
        @include media-breakpoint-up(md) {
            font-size: rem(14);
        }

        line-height: rem(20);
        font-family: $gotham-regular;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        border-bottom: rem(1) solid $form-control-border-color;
        position: relative;
        z-index: inherit;
        float: inherit;
        width: 100%;
        margin-bottom: 0;
        padding-top: rem(24);

        &::placeholder {
            font-size: rem(14);
            line-height: rem(20);
            font-family: $gotham-regular;
        }

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;

            & ~ .float-label {
                top: rem(8);
                font-size: rem(10);
                color: $form-control-focused-color;
                line-height: rem(16);
            }
        }

        &.is-invalid {
            background-image: none;

            + label {
                top: rem(8);
                font-size: rem(10);
                color: $form-control-focused-color;
                line-height: rem(16);
            }
        }
    }

    label {
        position: absolute;
        top: rem(8);
        color: $emperor;
        font-size: rem(14);
        line-height: rem(20);
        font-family: $gotham-regular;
        transition: 0.2s ease all;
    }

    .float-label {
        left: 0;
        top: rem(24);
        font-size: rem(14);
        line-height: rem(20);
        font-family: $gotham-regular;
        pointer-events: none;
        width: 100%;
    }
    /* active state */
    input:focus ~ .float-label,
    input:not(:placeholder-shown) ~ .float-label {
        top: rem(8);
        font-size: rem(10);
        color: $form-control-focused-color;
        line-height: rem(16);
    }

    @supports (-ms-ime-align: auto) {
        .form-control:focus ~ .float-label,
        .form-control:valid ~ .float-label {
            top: rem(8);
            font-size: rem(10);
            color: $form-control-focused-color;
            line-height: rem(16);
        }
    }

    select:focus ~ .float-label,
    select:valid ~ .float-label {
        top: rem(8);
        font-size: rem(10);
        color: $form-control-focused-color;
        line-height: rem(16);
    }

    /* validation error  */
    .form-control.is-invalid {
        border-bottom: rem(1) solid $error-border-color;
        color: $error-text-color;
    }

    .invalid-feedback {
        margin-top: rem(8);
        font-size: rem(12);
        line-height: rem(16);
        color: $error-text-color;
        font-family: $gotham-regular;
    }

    .custom-select-wrapper {
        .custom-select {
            background: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: rem(28);
            right: 0;
            width: rem(10);
            height: rem(10);
            border-bottom: rem(1) solid $select-icon-border-color;
            border-right: rem(1) solid $select-icon-border-color;
            transform: rotate(45deg);
            transition: all 0.4s;
        }
    }

    &.required .form-control-label::before {
        content: '';
    }
}
.terms-conditions,
.tertiary-link {
    font-size: rem(12);
    line-height: rem(16);
}
.tertiary-link.footer-tertiary-link {
    font-size: rem(12);
    line-height: rem(16);
    text-underline-offset: rem(3);
}
.input-group.custom {
    .form-control {
        width: 1%;
    }
}
footer hr {
    margin-top: rem(24);
    margin-bottom: rem(24);
}
.footer-email-signup label[for=email-signup]::after {
    content: '*';
}
.shipping-footer-banner.row {
    margin-bottom: rem(-24);
}
/* custom amount field */
.custom-amount {
    label {
        font-family: $gotham-semibold;
        font-size: rem(14);
        line-height: rem(32);
        letter-spacing: rem(0.4);
        margin-bottom: rem(16);
    }

    .form-control {
        width: rem(170);
        height: rem(40);
        border: rem(1) solid $form-control-border-color;
        border-radius: 0;

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            border: rem(1) solid $error-border-color;
            color: $error-text-color;
            background-image: none;
        }

        &::placeholder {
            font-size: rem(14);
        }
    }
}

/* custom textarea */
.custom-textarea {
    position: relative;
    margin-top: rem(24);

    label {
        color: $form-control-text-color;
        font-size: rem(14);
        font-family: $gotham-regular;
        margin-bottom: rem(8);
    }

    .custom-textarea-input {
        display: block;
        resize: none;
        border: rem(1) solid $form-control-border-color;
        border-radius: 0;
        resize: none;

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            border: rem(1) solid $error-border-color;
            color: $error-text-color;
        }
    }
}

/* custom checkbox */
.custom-checkbox {
    .custom-control-label {
        line-height: rem(24);
        font-size: rem(12);
        font-family: $gotham-regular;

        &::before {
            border: rem(1) solid $custom-control-border-color;
            background: $custom-control-background-color;
            border-radius: 0;
            width: rem(16);
            height: (16);
        }
    }

    .custom-control-input {
        left: rem(-1);
        top: rem(4);
        z-index: $zindex-low;
        width: rem(18);
        height: rem(18);

        &:checked ~ .custom-control-label::before {
            color: $custom-control-text-color;
            border: rem(1) solid $custom-control-border-color;
            background-color: $custom-control-background-color;
        }

        &:checked ~ .custom-control-label::after {
            background-image: none;
            width: rem(5);
            height: rem(10);
            border: rem(1) solid $custom-control-border-color;
            transform: rotate(45deg);
            border-top: none;
            border-left: none;
            left: rem(-19);
            top: rem(6);
        }

        &:focus ~ .custom-control-label::before {
            box-shadow: none;
            border-color: $custom-control-border-color;
        }

        &:not(:disabled):active ~ .custom-control-label::before {
            color: $custom-control-background-color;
            background-color: $custom-control-inactive-background-color;
            border-color: $custom-control-inactive-border-color;
        }
    }
}

/* custom radio */
.custom-radio {
    position: relative;

    .form-check-label {
        padding-left: rem(32);
        font-size: rem(14);
        line-height: rem(20);
        font-family: $gotham-regular;

        .svg-icon {
            margin-right: rem(4);
            vertical-align: bottom;
        }

        &::before {
            content: '';
            width: rem(16);
            height: rem(16);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            background-image: none;
            border: rem(1) solid $custom-control-border-color;
            background-color: $custom-control-background-color;
        }
    }

    .form-check-input {
        margin-top: rem(2);
        margin-left: rem(-14);
        opacity: 0;

        &:checked ~ .form-check-label::after {
            content: '';
            width: rem(12);
            height: rem(12);
            background-color: $custom-checkbox-background-color;
            border-radius: 50%;
            position: absolute;
            left: rem(2);
            top: rem(2);
            border: rem(1) solid $custom-checkbox-border-color;
        }
    }
}

/* credit card form styles */
select.expirationYear:not([value='']) ~ .float-label,
select.expirationMonth:not([value='']) ~ .float-label,
select.radius:not([value='']) ~ .float-label {
    top: rem(8);
    font-size: rem(10);
    color: $form-control-focused-color;
    line-height: rem(16);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Billing address address selector */
.address-selector-block .form-group.custom .custom-select-wrapper .custom-select.addressSelector {
    padding-bottom: rem(16);
    height: auto;
    // border-top: rem(1) solid $billing-address-border-color;
    margin-top: rem(16);
    white-space: normal;
    @include media-breakpoint-up(md) {
        border-top: 0;
        margin-top: 0;
        white-space: nowrap;
    }
}

.mask {
    font-family: $text-security-disc !important;

    // to avoid unexpected behavior for iOs devices
    @include media-breakpoint-up(lg) {
        -webkit-text-security: disc !important;
    }
}