.carousel-stripe-banner {
    background: $header-banner-background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zindex-low;
    height: rem(40);

    .carousel-inner {
        width: auto;
        flex: 1;
        text-align: center;
    }

    .carousel-item {
        padding: rem(12);
        line-height: rem(16);
        a {
            font-size: rem(12);
            color: $stripe-text-color;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
    }

    .arrow {
        border-color: $stripe-icon-color;
    }

    .close-banner {
        .close {
            opacity: 1;
        }
    }

    svg {
        width: rem(12);
        fill: $stripe-icon-color;
    }

    button,
    .control {
        padding: 0 rem(14);
    }

    .close {
        opacity: 1;
    }
}

.carousel-stripe-banner-container.show {
    height: rem(40);
}
