@import 'productCard';

.minicart-link {
    -webkit-appearance: none;
    padding: 0;
}

#minicart {
    .modal-body {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        // .modal-content height and .modal-header vertical margins must be calculated together
        // reducing margin in the header leads to the content not vertically filling the viewport.
        $minicart-header-adjustment-px: 8;

        .modal-content {
            height: calc(100% + rem($minicart-header-adjustment-px));
        }

        .modal-header {
            margin-bottom: rem(40 - $minicart-header-adjustment-px);
        }
    }

    .cart .gwp-container {
        border: none;
        padding-bottom: 0;
    }

    .minicart-footer {
        border: none;
    }

    .modal-body {
        height: calc(100% - 56px);

        @include media-breakpoint-up(md) {
            height: calc(100% - 104px);
        }
    }

    .cart-empty {
        display: none;
    }

    .empty-cart-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .content-asset {
            flex: 1;
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .cart {
        background-color: $white;
        display: flex;
        justify-content: space-between;
        padding: 0;
    }

    .card {
        border: none;
        width: 100%;
        margin-bottom: rem(32);
    }

    .card-body {
        width: 100%;
        border: none;
        padding: 0;
    }

    .remove-line-item > .remove-btn {
        margin-top: rem(20);
        color: $emperor;
        font-family: $gotham-regular;
        font-size: rem(12);
        letter-spacing: rem(0.4);
        line-height: rem(24);
        text-decoration: underline;
        position: static;
        width: auto;
    }

    .sub-total-with-value {
        color: $emperor;
        font-family: $gotham-semibold;
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(24);
        text-align: center;
    }

    .approaching-discounts {
        margin-top: 0;
        text-align: center;
    }
}

.minicart-error {
    width: 100%;
    padding: 0 rem(28);

    @include media-breakpoint-down(md) {
        padding: 0 rem(16);
    }

    .cart-error-message {
        text-align: left;
        margin-top: 0;
        margin-bottom: rem(24);
        padding: rem(16) 0 0 0;

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }
    }
}

.footer-divider {
    margin: 0 rem(-16) rem(16);

    @include media-breakpoint-up(md) {
        margin: 0 rem(-40) rem(16);
    }
}

.mini-cart-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 rem(-30);

    @include media-breakpoint-down(md) {
        margin: 0 rem(-16);
    }

    .price-display {
        flex-direction: column;
        align-items: flex-end !important; //To override OOTB code
    }

    .final-sale-message {
        margin-bottom: 0;
    }

    .line-item-header {

        div,
        p {
            &.line-item-name {
                white-space: normal;
                margin-bottom: 0;
                font-size: rem(18);
                line-height: rem(24);
            }
        }

        ~.line-item-attributes {
            margin-top: 0;

            .color-value {
                padding-left: rem(8);
            }
        }
    }

    .cart {
        flex-direction: column;
        height: 100%;

        .gwp-container {
            .gwp-title {
                border: none;
                margin: rem(32) 0 rem(16);
                padding: 0;
            }

            .product-line-item-details {
                margin-bottom: rem(32);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .line-item-name {
                white-space: nowrap;
                color: $base-text-color;
                font-family: $crimson;
                font-size: rem(18);
                line-height: rem(24);
                font-weight: normal;
                width: 100%;
            }
        }

        .mini-cart-scrollable-products {
            flex: 1;
            margin: 0;
            padding: 0 rem(28);
            scrollbar-gutter: stable;
            width: 100%;

            @include media-breakpoint-down(md) {
                padding: 0 rem(16);
            }
        }

        .mini-cart-line-items {
            padding-bottom: rem(24);
        }

        .sticky-mini-cart-footer {
            width: 100%;
            padding: 0 rem(30);

            @include media-breakpoint-down(md) {
                padding: 0 rem(16);
            }
        }
    }
}

.mini-cart-promos {
    margin: rem(32) rem(-40) 0;

    @include media-breakpoint-down(sm) {
        margin: rem(32) rem(-16) 0;
    }
}

.gwp-title {
    font-family: $gotham-bold;
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: rem(1);
    margin-top: rem(80);
    padding-bottom: rem(8);
    margin-bottom: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        border-bottom: none;
        margin-top: rem(40);
        padding-bottom: 0;
        border-top: rem(1) solid $gwp-title-border-color;
        padding-top: rem(24);
    }
}

.cart-error-message {
    color: $cart-error;
    font-family: $gotham-regular;
    font-size: rem(12);
    letter-spacing: rem(0.4);
    line-height: rem(16);
    margin-top: rem(16);
    margin-bottom: 0;
    text-align: center;
    padding: 0 rem(32);

    @include media-breakpoint-up(md) {
        margin-top: rem(24);
        margin-bottom: rem(8);
        padding: 0;
    }
}

.gwp-container {
    .item-message {
        margin: 0;
        font-size: rem(12);
    }
}

.out-of-stock-img {
    filter: grayscale(1);
}

.price {
    div[class*='item-adjusted'] {
        color: $sale-price-color;
    }
}

.mini-cart-container {
    .item-attributes {
        margin: 0;
        padding-left: rem(16);
        line-height: rem(16);

        .line-item-attributes {
            margin-top: rem(0);
        }

        .attribute-name,
        .attribute-value {
            line-height: rem(20);
        }

        .attribute-name {
            min-width: rem(48);
        }

        .attribute-value {
            padding-left: rem(8);
        }
    }

    .free-text {
        color: $product-total-price-color;
        font-family: $gotham-semibold;
        font-weight: normal;
        font-size: rem(14);
        line-height: rem(20);
    }

    .product-line-item-details {
        .coupon-description {
            display: none;
        }
    }

    .info-messages {
        padding-left: rem(16);
    }
}

.coupon-description {
    font-family: $gotham-regular;
    font-size: rem(12);
    letter-spacing: rem(0.4);
    line-height: rem(16);
    color: $coupon-code-color;
}

.info-messages {
    .line-item-availability {
        margin-top: rem(8);

        .line-item-attributes {
            line-height: rem(16);

            .similar-styles-category-link {
                padding-left: rem(4);
                color: $link-color;
                text-decoration: underline;
            }
        }
    }

    .final-sale-message {
        margin-top: rem(8);
        padding-right: 0;
    }
}
