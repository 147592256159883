.popover {
    font-family: $gotham-regular;
    font-size: rem(12);
    line-height: rem(16);
    letter-spacing: rem(0.4);
    color: $form-control-text-color;
    border: 1px solid $form-control-border-color;
    border-radius: 0;

    &-body {
        letter-spacing: rem(0.4);
        font-family: $gotham-regular;

        p {
            margin-top: rem(16);
            margin-bottom: 0;
            line-height: rem(16);

            strong {
                font-family: $gotham-semibold;
                font-size: rem(12);
                color: $grey-text;
            }
        }
    }
}
body {
    &.modal-open {
        > .popover {
            z-index: $zindex-max-highest;
        }
    } 
    > .popover {
        z-index: $zindex-medium;
    }
}
