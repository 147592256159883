@import './stripeBanner';

header {
    position: relative;
    background: $header-background-color;
    z-index: $zindex-highest;

    .has-icon {
        display: inline-block;
    }

    .has-icon:focus,
    .header-nav-icons .search-toggle button:focus,
    .wishlist a:focus,
    .minicart-link:focus {
        outline: rem(2) dotted $header-outline-color;
    }

    .set-preferred-store {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        .navbar.main-nav {
            position: static;
        }
    }
}

header .arrow,
.order-history .arrow,
.carousel-stripe-banner .arrow {
    overflow: hidden;
    display: inline-block;
    font-size: rem(12);
    width: rem(8);
    height: rem(8);
    margin-top: -2em;
    border-top: 1px solid $header-text-color;
    border-left: 1px solid $header-text-color;

    &.left {
        transform: rotate(-54deg) skew(-20deg);
    }

    &.right {
        transform: rotate(135deg) skew(-6deg);
    }
}

.carousel-stripe-banner {
    .arrow {
        border-top: rem(1) solid $stripe-icon-color;
        border-left: rem(1) solid $stripe-icon-color;
    }

    .control .sr-only {
        color: $white;
    }
}

.browser-warning {
    position: relative;
    background-color: $alert-info-background-color;
    font-size: rem(13);
    line-height: 1.25;
    padding: rem(16);
    text-align: center;
    z-index: $zindex-low;

    a {
        text-decoration: underline;
    }
}

.header-wrapper,
.back-arrow-container {
    max-width: rem(1440);
    margin: auto;

    .search-menu {
        left: 0;
        z-index: $zindex-higher;
    }
}

/* sticky header position */
.sticky.is-sticky,
.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: $zindex-highest;
    width: 100%;
    background-color: $header-background-color;
    @include media-breakpoint-up(lg) {
        .main-nav {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .header .dropdown-menu {
        @include media-breakpoint-up(lg) {
            top: 100%;
        }
    }
}

.main-nav {
    color: $header-text-color;
    position: relative;
    top: 0;
    padding-right: 0;
    background: $header-background-color;
    transition: all ease-in-out 300ms;

    @include media-breakpoint-down(md) {
        display: flex;
        padding-left: 0;
    }

    a {
        color: $menu-link;

        &:active {
            color: $menu-link;
        }
    }

    .highlighted-category {
        color: $header-highlighted-color;

        &:active {
            color: $header-highlighted-color;
        }
    }

    .header-nav-logo {
        margin-right: rem(40);
    }

    .header-nav-icons {
        .icons-content {
            float: none;

            @include media-breakpoint-down(md) {
                justify-content: space-between !important; // To overide bootstrap justifying on mobile
            }
        }

        svg {
            width: rem(16);
            height: rem(16);
        }

        svg.lp-logo {
            width: rem(112);
            height: rem(32);
        }

        .minicart-total {
            position: relative;

            .minicart-quantity {
                font-size: rem(8);
                position: absolute;
                top: rem(5);
                left: rem(3);
            }
        }

        &-left,
        &-right {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .navbar-toggler {
            padding: 0;
        }

        .user,
        .user-account-menu {
            padding: 0;
            @include media-breakpoint-up(lg) {
                padding: 0 rem(8);
            }
        }

        .search-toggle {
            padding: 0 0 0 rem(24);
            @include media-breakpoint-up(lg) {
                padding: 0 rem(8);
            }
        }

        .store-locator-header-nav {
            padding: 0 0 0 rem(24);
            display: none;

            @include media-breakpoint-up(lg) {
                padding: 0 rem(8);
                display: flex;
            }
        }

        .wishlist {
            padding: 0 rem(24) 0 0;
            @include media-breakpoint-up(lg) {
                padding: 0 rem(8);
            }
        }

        .minicart {
            padding: 0;
            margin: 0;
            @include media-breakpoint-up(lg) {
                padding: 0 0 0 rem(8);
            }

            .minicart-link {
                display: flex;
            }

            .icon-tote,
            .icon-tote-active {
                display: flex;
            }
        }
        @include media-breakpoint-between(md, lg) {
            position: static;
        }
    }

    .header-nav-logo svg {
        width: rem(120);
    }

    .wishlist {
        a {
            display: block;
        }
    }

    .user-account-menu {
        position: initial;

        .dropdown-menu {
            position: absolute;
            top: 90%;
            left: 0;
            padding: rem(24);
            width: 100%;
            border: none;
            border-radius: none;

            @include media-breakpoint-only(lg) {
                padding: rem(24) rem(16) rem(32) rem(32);
                left: 75.5%;
                width: 24.5%;
            }

            @include media-breakpoint-up(xl) {
                &.non-logged-in-user {
                    padding: rem(16) rem(24);
                }
            }
            a, button {
                display: block;
                padding: rem(8);
                font-size: rem(12);
                font-family: $gotham-regular;
                background: transparent;
                color: $header-text-color;
                line-height: rem(16);

                @include media-breakpoint-only(lg) {
                    padding: rem(4) 0;
                }

                &.loyalty-dashboard-link {
                    position: relative;
                    vertical-align: middle;
                }

                &.loyalty-program-drawer-link {
                    position: relative;
                    vertical-align: middle;
                    padding-bottom: 0;

                    @include media-breakpoint-only(lg) {
                        padding: 0;
                        margin-top: rem(8);
                    }
                }
                .clublilly-cta-title {
                    position: relative;
                    top: rem(5);
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .user-message {
                font-family: $gotham-semibold;
                text-transform: capitalize;
                font-size: rem(12);
                padding-left: rem(8);
                padding-bottom: rem(8);

                @include media-breakpoint-only(lg) {
                    padding-left: 0;
                }
            }

            ul {
                flex-flow: column;
            }
        }

        .dropdown-toggle::after {
            content: none;
        }
    }
}

.new-loyalty-badge {
    border: 1px solid $loyalty-new-tag-color;
    font-family: $gotham-semibold;
    padding: rem(1) rem(8);
    font-size: rem(12);
    line-height: rem(20);
    border-radius: rem(5);
    color: $loyalty-new-tag-color;
    position: relative;
    top: rem(-3);

    @include media-breakpoint-down(md) {
        width: auto;
        padding: rem(2) rem(10);
        top: 0;
        margin: 0 0 0 auto;
    }
}

.new-loyalty-badge-color {
    border: rem(1) solid $loyalty-guest-new-tag-color;
    color: $loyalty-guest-new-tag-color;
}

.header {
    padding: rem(14) 0 0;
    position: static;
    color: $header-text-color;
    @include media-breakpoint-up(lg) {
        padding: rem(14) 0;
    }

    .navbar {
        position: static;
    }

    &.main-menu {
        background-color: $header-background-color;
        padding: 0;
    }

    .nav-item.show {
        position: unset;
    }

    .navbar-nav .nav-item {
        .nav-link {
            @include media-breakpoint-up(lg) {
                padding: rem(20) rem(8);
            }
            @media only screen and (min-width: rem(1200)) {
                padding: rem(20) rem(16);
            }
        }

        border: none !important; // Important to override bootstrap borders
    }

    .find-store-link {
        padding-top: rem(16);
    }

    .mobile-username {
        font-family: $gotham-semibold;
        text-transform: capitalize;
        font-size: rem(12);
        padding: rem(12) rem(0) rem(12) rem(16);
        padding-bottom: rem(8);
        margin-top: rem(4);
    }
    svg {
        margin-right: rem(10);
    }
    .mobile-nav-item {
        margin-left: rem(30);
    }

    .navbar-expand-md .navbar-nav {
        @include media-breakpoint-down(md) {
            flex-flow: column;

            .nav-link {
                padding-left: rem(16);
                padding-right: rem(16);
            }
        }
    }

    .nav-item > .nav-link {
        font-size: rem(12);
        letter-spacing: rem(0.4);
        line-height: rem(16);
        font-family: $gotham-semibold;
        border-bottom: rem(1) solid transparent;

        &::after,
        &::before {
            content: '';
            width: 0;
            height: rem(1);
            position: absolute;
            bottom: 0;
            border: none;
            padding: 0 !important; //overriding bootstrap style
            background: none;
            transition: all 350ms ease-in;
            margin: 0;
            @include media-breakpoint-up(lg) {
                background: $header-border-color;
            }
        }

        &::before {
            right: 50%;
        }

        &::after {
            left: 50%;
        }

        /* Safari only fix. It doesn't support transitions for pseudo selectors */
        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
                &::before,
                &::after {
                    transition: none;
                }
            }
        }

        @include media-breakpoint-up($next-breakpoint) {
            &:hover,
            &:focus {
                outline: none;

                &::before,
                &::after {
                    width: 50%;
                }
            }
        }
    }

    .no-bold>.nav-link {
        fill: $base-text-color;
        font-family: $gotham-regular;

        svg {
            margin-right: rem(10);
        }
    }

    .dropdown {
        &-menu {
            top: 97%;
        }

        &-container {
            position: absolute;
            left: 0;
            right: 0;
            z-index: $zindex-lowest;
            background: $header-background-color;
            margin: 0;
            display: none;

            @include media-breakpoint-up(lg) {
                display: block !important; // to override bootstrap default style for mega menu
                max-height: 0;
                padding: 0 rem(16);
            }

            .sub-menu-wrapper {
                display: flex;
                flex-flow: column;
                @include media-breakpoint-up(lg) {
                    padding: 0 rem(16);
                    flex-flow: row;
                    max-width: rem(1440);
                    margin: auto;
                    width: 100%;
                }

                .dropdown-container-menu {
                    @include media-breakpoint-up(md) {
                        max-width: rem(200);
                    }

                    .dropdown-link {
                        white-space: normal;
                        background-color: transparent;
                        padding-left: 0;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            a {
                font-family: $gotham-regular;
                font-size: rem(12);
                line-height: rem(16);
            }

            .sub-cat-link {
                display: block;
                padding: 0 1.5rem;
                margin-bottom: rem(12);
                font-family: $gotham-semibold;
                font-size: rem(12);
                max-width: rem(200);
                color: $base-text-color;
                @include media-breakpoint-down(md) {
                    margin: rem(32) 0 rem(16);
                }
            }

            .see-all-link {
                text-align: center;
                padding: rem(16);
                text-decoration: underline;
            }

            li {
                display: block;
                width: 100%;
                padding: 0.25rem 1.5rem;
                clear: both;
                min-width: rem(200);
                color: inherit;
                white-space: nowrap;
                background-color: transparent;
                border: 0;

                img {
                    display: block;
                }
            }

            #print {
                .sub-cat-link {
                    padding-left: rem(16);
                }

                .dropdown-container-menu {
                    display: flex;
                    flex-wrap: wrap;
                    @include media-breakpoint-up(lg) {
                        max-width: rem(500);
                    }

                    li {
                        flex: 50%;
                        padding-left: rem(16);
                        @include media-breakpoint-down(md) {
                            img {
                                width: 100%;
                            }
                        }

                        img {
                            display: block;
                        }

                        .dropdown-link {
                            font-family: $crimson;
                        }
                    }
                }
            }

            .banner-wrapper {
                @include media-breakpoint-down(md) {
                    order: 5;
                }
            }

            .banner-card {
                text-align: center;
                padding-right: rem(32);

                > * {
                    margin: 0 0 rem(8);
                }

                @include media-breakpoint-down(md) {
                    padding: 0 rem(24);
                    display: flex;
                    flex-direction: column;

                    > * {
                        margin: rem(8) 0;
                    }
                }

                .banner-img {
                    @include media-breakpoint-down(md) {
                        order: 3;
                    }

                    img {
                        max-width: 100%;
                        @include media-breakpoint-up(lg) {
                            width: rem(416);
                            max-width: initial;
                        }
                    }
                }

                a {
                    text-decoration: underline;
                    letter-spacing: rem(0.4);
                    line-height: rem(24);
                    @include media-breakpoint-down(md) {
                        order: 2;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-bottom: rem(24);
                    }
                }

                p {
                    font-family: $crimson;
                    letter-spacing: rem(0.6);
                    margin-bottom: rem(0);
                    margin-top: rem(32);
                    font-size: rem(18);
                    line-height: rem(24);
                    text-transform: capitalize;
                    @include media-breakpoint-up(lg) {
                        margin-top: rem(16);
                        margin-bottom: rem(8);
                    }
                    @include media-breakpoint-down(md) {
                        order: 1;
                    }
                }
            }

            &.is-checked {
                @include media-breakpoint-down(md) {
                    .banner-wrapper {
                        order: 0;
                    }
                }
            }

            &.banner-position-top {
                .banner-wrapper {
                    @include media-breakpoint-down(md) {
                        order: 0;
                    }
                }
            }
        }
    }

    .dropdown-item {

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
        }

        &.top-category {

            &:hover,
            &.active {
                @include media-breakpoint-down(md) {
                    background: none;
                }
            }
        }
    }

    .dropdown {
        position: unset;

        &.show {
            .nav-link {
                &::before,
                &::after {
                    width: 50%;
                }
                @include media-breakpoint-down(md) {
                    white-space: normal;
                    padding-right: rem(20);
                }
            }
        }
    }

    .dropdown-menu {
        position: unset;
        box-shadow: none;

        &.show {
            max-height: rem(500);
            padding: rem(32) rem(16);
            z-index: $zindex-low;
            min-height: rem(484); // height of the larger menu
            margin-top: rem(-1);
        }
        @include media-breakpoint-up(lg) {
            top: 91%;
        }
        @include media-breakpoint-down(md) {
            flex-flow: column;
        }
    }

    .navbar {
        padding: 0;
    }
}

.menu-toggleable-left .close-menu {
    display: none;
}

.navbar-toggler {
    .icon-close {
        display: none;
    }

    .icon-ham {
        display: flex;
    }
}

.menu-toggleable-left.in + .header-nav-icons {
    .navbar-toggler {
        .icon-close {
            display: block;
        }

        .icon-ham {
            display: none;
        }
    }
}

.menu-toggleable-left.in .dropdown-menu {
    display: none;
    @include media-breakpoint-down(md) {
        padding-bottom: rem(24);
    }
}

.menu-toggleable-left.in .show > .dropdown-menu {
    display: block;
}

/* for 200% zoom */
.menu-toggleable-left.in .show > .dropdown-menu {
    @include media-breakpoint-between(md, lg) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 0;
        -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        display: block;
    }
}

.menu-toggleable-left {
    .show > .dropdown-menu {
        overflow-x: auto;
    }

    .top-category {
        text-align: center;
        display: none;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row-reverse;
        padding: 0 rem(18);

        &:first-child {
            display: flex;
        }

        &:not(:first-child) {
            display: none !important;
        }

        .right {
            display: none;
            opacity: 0;
        }

        a {
            flex: 100%;
            text-align: center;
            font-weight: 600;
        }
    }

    .nav-link:focus {
        outline: none;
        @include media-breakpoint-up(lg) {
            background: $header-focus-background-color;
        }
    }

    .nav-item .nav-link,
    .sub-cat-link,
    .dropdown-container li a {
        font-size: rem(12);
        line-height: rem(24);
        position: relative;
        @include media-breakpoint-up(lg) {
            line-height: rem(16);
        }

        .arrow {
            position: absolute;
            right: rem(20);
            top: rem(40);
        }
    }
}

.menu-toggleable-left.navbar-toggleable-md {
    top: rem(48);

    @include media-breakpoint-down(md) {
        max-width: 80%;

        &.in {
            left: rem(-1);
            height: 100%;
            overflow: auto;
        }
    }
}

// adjusting hamburd menu postion based on banner avaivlbity and stick header
.has-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(88);
        height: 100%;
        overflow: auto;
    }
}

// adjusting hamburd menu postion based on banner, skinny banner availability and stick header
.has-skinny-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(168);
        height: calc(100% - 168px);
    }
}
.has-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(88);
        height: 100%;
        overflow: auto;
    }
    &.has-skinny-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(208);
            height: calc(100% - 208px);
        }
    }
}

.is-sticky {
    .has-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(48);
            height: 100%;
            overflow: auto;
            padding: 0;
        }
    }
    .has-skinny-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(168);
            height: calc(100% - 168px);
            padding: 0;
        }
    }
}

.mobile-menu-open {
    overflow: hidden;
    // The below code is to restrict scroll on ios/safari when hamburg menu is open
    // since just overflow hidded doesn't work in mobile ios/safari
    height: 100%;
    width: 100%;
    position: fixed;

    .modal-background {
        display: block !important;
        @include media-breakpoint-up(lg) {
            top: rem(50);
        }
    }
}

.skinny_banner {
    position: relative;
    z-index: $zindex-low;
}
@include media-breakpoint-down(md) {
    .skinny_banner {
        .evg-container {
            height: rem(104);
        }
    }
    .navbar.main-nav {
        .menu-toggleable-left.navbar-toggleable-md.in {
            position: fixed;
            top: rem(48);
            height: calc(100% - 48px);
        }
        &.has-skinny-banner {
            .menu-toggleable-left.navbar-toggleable-md.in {
                position: fixed;
                top: rem(168);
                height: calc(100% - 168px);
            }
        }

        &.has-banner {
            .menu-toggleable-left.navbar-toggleable-md.in {
                position: fixed;
                top: rem(88);
                height: calc(100% - 88px);
            }
            &.has-skinny-banner {
                .menu-toggleable-left.navbar-toggleable-md.in {
                    position: fixed;
                    top: rem(208);
                    height: calc(100% - 208px);
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #girls-shop-by-size {
        .sub-cat-link,
        li {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
