.rendering-template, .customer-service {
    .accordion-container {
        display: block;

        &:first-child .card {
            border-top: 0;
        }

        .card-body {
            padding: 0;

            .content {
                margin-top: rem(16);
            }
        }

        .card-header {
            .btn {
                .icon {
                    position: absolute;
                    right: 0;
                    top: rem(4);
                }

                &::after {
                    display: none;
                }
            }

            .collapsed {
                .expand-icon {
                    display: inline-block;
                }

                .collapse-icon {
                    display: none;
                }
            }

            .expand-icon {
                display: none;
            }

            .collapse-icon {
                display: inline-block;
            }
        }

        .expand-all {
            position: absolute;
            right: 0;
            text-decoration: underline;
            color: $base-text-color;
            font-family: $gotham-regular;
            font-size: rem(12);
            font-weight: 300;
            letter-spacing: rem(0.4);
            line-height: rem(24);

            @include media-breakpoint-up(md) {
                font-size: rem(14);
            }
        }
    }
}
