@import './collapsibleItem';

footer {
    background-color: $footer-backgroud-color;
    padding-top: rem(64);
    padding-bottom: 2.5em;
    @include media-breakpoint-up(md) {
        padding-top: rem(104);
    }

    hr {
        border-top-color: $footer-border-color;
    }

    .footer-section-title {
        font-family: $gotham-semibold;
        font-size: rem(14);
        color: $footer-title-color;
        margin-bottom: rem(12);
        line-height: rem(20);
    }
    .footer-section-message {
        line-height: rem(20);
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    .phone-icon,
    .mail-icon,
    .sms-icon {
        margin-right: rem(8);
    }
    .sms-icon {
        vertical-align: text-top;
    }

    .follow-title {
        margin-bottom: rem(16);
    }

    .mail-icon {
        fill: $footer-title-color;
    }

    .social-link-icons {
        @include clearfix;

        @include media-breakpoint-down(md) {
            width: 80%;
            margin-bottom: rem(16);
        }

        display: flex;
        float: left;
        position: relative;
        z-index: $zindex-lowest;

        li {
            margin-right: rem(16);

            .svg-icon {
                width: rem(24);
                height: rem(24);
            }
        }

        a {
            display: inline-block;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .footer-links {
        display: flex;
        position: relative;
        bottom: rem(32);
        text-decoration: underline;
        width: 75%;
        flex-wrap: wrap;

        li {
            font-size: rem(12);
        }
    }

    .content {
        font-family: $gotham-regular;
        font-size: rem(14);
        line-height: rem(20);

        li {
            line-height: 1.875rem;
            height: auto;

            a {
                color: $footer-title-color;
            }
        }
    }

    .footer-email-signup {
        background-color: $footer-backgroud-color;

        .input-group-append {
            margin-top: rem(24);
            margin-bottom: rem(32);
        }
    }

    .modal {
        .modal-header {
            border: 0;
            padding: 0;

            .close {
                margin-top: rem(24);
                margin-right: rem(40);
                margin-bottom: rem(8);
                padding: 0;
            }
        }

        .success-modal-title {
            color: $base-text-color;
            font-family: $gotham-semibold;
            font-size: rem(16);
            line-height: rem(24);
            text-align: center;
        }

        .success-modal-body {
            color: $base-text-color;
            font-family: $gotham-regular;
            font-size: rem(14);
            line-height: rem(20);
            text-align: center;
            margin: rem(8) rem(40) rem(48) rem(40);
        }
    }

    @include media-breakpoint-down(md) {
        .collapsible-md .footer-links {
            margin: rem(16) 0;
            width: 100%;
        }

        .content-asset {
            flex: 1;
        }

        .social-links {
            display: flex;

            li {
                width: auto;
            }
        }
    }
    @include media-breakpoint-between(md, lg) {
        .collapsible-md .title::after {
            display: none;
        }

        .collapsible-md .content {
            display: initial;
        }

        .collapsible-md .footer-links {
            display: flex;
        }
    }
}
