.size-chart {
    text-align: right;
    margin: rem(8) 0;

    > a {
        line-height: 24px;
        letter-spacing: rem(0.4);
        margin-right: rem(16);
        text-decoration: underline;
    }
}

.lp-sizechart {
    text-align: left;
    font-family: $gotham-regular;
    font-size: rem(12);
    line-height: rem(16);
    color: $grey-text;

    h2,
    &-category-btn {
        font-family: $crimson;
        font-size: rem(24);
        line-height: rem(40);
        margin-bottom: 0;
        background: none;
        border: 0;
        padding: 0;
    }

    &-category-btn {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        &::after {
            content: '';
            transform: rotate(45deg);
            height: rem(12);
            width: rem(12);
            border-bottom: rem(1) solid $grey-text;
            border-right: rem(1) solid $grey-text;
            margin-right: rem(3);
            transition: transform 0.3s ease;
        }
    }

    h3 {
        font-family: $gotham-semibold;
        font-size: rem(14);
        line-height: rem(20);
        margin-bottom: rem(4);
    }

    p {
        letter-spacing: rem(0.4);
    }

    article {
        h3 {
            margin-bottom: rem(4);
        }

        margin-bottom: rem(24);

        &:first-child {
            margin-top: rem(16);
        }
    }

    &-category-dropdown {
        width: 40%;

        &-menu {
            position: absolute;
            background-color: $sizechart-menu-bg;
            flex-direction: column;
            margin-left: rem(-16);
            z-index: $zindex-lowest;
            border: 0;
            padding: 0;
            overflow: hidden;
            height: 0;
            transition: all 0.3s;
            width: 40%;

            &.nav-tabs {
                border: 0;
            }

            .nav-item {
                .nav-link {
                    color: $grey-text;
                    font-size: rem(14);
                    line-height: rem(24);
                    padding: rem(4) rem(16);
                    position: relative;
                    transition: all 0.3s;

                    &:hover {
                        opacity: 0.4;
                    }

                    &.active {
                        background-color: $sizechart-menu-bg;
                        border-bottom: 0;

                        &::before {
                            content: '';
                            transform: rotate(45deg);
                            height: rem(10);
                            width: rem(5);
                            border-bottom: rem(1) solid $grey-text;
                            border-right: rem(1) solid $grey-text;
                            position: absolute;
                            left: rem(3);
                            top: rem(8);
                        }
                    }
                }
            }
        }

        &.show-menu {
            .lp-sizechart-category-dropdown-menu {
                height: auto;
                padding: rem(12) 0;
            }

            .lp-sizechart-category-btn {
                &::after {
                    content: '';
                    transform: rotate(-135deg);
                }
            }
        }
    }

    .swim-tabcontent {
        margin-bottom: rem(40);

        &-nav {
            margin-bottom: rem(40);

            li {
                width: 50%;
                text-align: center;

                a {
                    margin: 0 auto;
                    font-size: rem(14);
                    line-break: rem(20);
                    padding: rem(10);
                    display: block;
                    text-decoration: none;

                    &.active {
                        border-bottom: rem(2) solid $active-border-bottom-navy;
                    }
                }
            }
        }

        .swim-sizechart-accordion {
            .swim-card {
                border-bottom: rem(1) solid $accordion-border-silver;

                &:first-child {
                    border-top: rem(1) solid $accordion-border-silver;
                }

                &-header {
                    height: rem(56);

                    button {
                        color: $grey-text;
                        padding: 0 rem(16);
                        width: 100%;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &::after {
                            content: '';
                            transform: rotate(45deg);
                            height: rem(12);
                            width: rem(12);
                            border-bottom: rem(1) solid $grey-text;
                            border-right: rem(1) solid $grey-text;
                            margin-right: rem(3);
                            display: block;
                        }

                        &[aria-expanded='true'] {
                            &::after {
                                transform: rotate(-135deg);
                            }
                        }
                    }
                }

                article {
                    margin-bottom: 0;

                    &:nth-of-type(odd) {
                        background-color: $table-row-grey;
                    }
                }

                .fit-img {
                    padding: rem(16) rem(24);

                    img {
                        width: 100%;
                        margin: 0 auto;
                        filter: grayscale(1);
                    }
                }

                .fit-desc {
                    padding: rem(8) rem(8) rem(8) rem(16);

                    ul {
                        padding: 0;
                        margin-bottom: 0;
                        list-style-position: inside;
                        letter-spacing: rem(0.4);
                        list-style: disc;
                        list-style-position: inside;
                    }

                    > p {
                        margin-bottom: rem(8);
                    }
                }
            }
        }
    }

    .size-chart-collapsible {
        &.active {
            .sizinginformation {
                right: 0;
            }
        }
    }

    .sizinginformation {
        background: $white;
        z-index: $zindex-max-highest;
        padding: rem(24) rem(18) rem(24) rem(40);
        overflow: auto;
        @include media-breakpoint-up(md) {
            overflow: unset;
        }

        &-container {
            height: 100%;
        }
    }

    &-header {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: rem(16);

        .lp-sub-text {
            font-family: $gotham-bold;
            font-size: rem(14);
            line-height: rem(20);
            text-transform: uppercase;
            letter-spacing: rem(1);
            margin-bottom: rem(32);
        }

        #sizechart-close {
            position: absolute;
            right: rem(40);
            top: rem(40);
            width: rem(16);
            height: rem(16);
            border: 0;
            display: table;
            padding: 0;
            background: none;
        }
    }

    &-content {
        padding-right: rem(28);
        height: 90%;
        padding-bottom: 6rem;
        overflow: visible;

        @include media-breakpoint-up(md) {
            padding-bottom: 0;
            overflow: auto;
        }
    }

    .sizechart {
        &.tab-pane.active {
            animation: opacity-show 0.5s ease-in-out;
        }
    }

    .table-container {
        margin-bottom: rem(24);
    }

    .sizecharttable {
        letter-spacing: rem(0.24);

        &.table-striped tbody tr:nth-of-type(even) {
            background-color: $table-row-grey;
        }

        &.table-striped tbody tr:nth-of-type(odd) {
            background-color: $table-row-white;
        }

        td,
        th {
            border: 0;
            padding: rem(12) rem(8);
            text-align: center;
            vertical-align: middle;
            font-weight: normal;
        }

        .firstRow {
            font-family: $gotham-semibold;
            text-align: center;
        }

        td[rowspan="2"] {
            vertical-align: top;
        }
    }

    .tips,
    .nofit {
        h2 {
            margin-bottom: rem(16);
            line-height: rem(32);
        }
    }

    .tips {
        margin-bottom: rem(40);
    }

    .btn {
        &-find-a-store {
            margin-bottom: rem(24);
            font-size: rem(14);
        }
    }

    .return-policy-link {
        font-size: rem(12);
        line-height: rem(24);
        letter-spacing: rem(0.4);
    }
}

@keyframes opacity-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.size-chart-visible {
    header,
    #carousel-stripe-banner,
    .is-sticky,
    .sticky.is-sticky {
        z-index: $zindex-lowest;
    }

    .lp-sizechart {
        z-index: $zindex-max-highest;
        position: relative;
    }
}
