#bazaarvoiceReviews{
    /****************
    *BV Action bar 
    */
    .bv-write-container{
        @include media-breakpoint-down(md) {
            float: right !important;
        }
    }
    .bv-write-review{
        @include media-breakpoint-down(md) {
            padding-top: 0 !important;
        }
    }
    /****************
    *Slider
    */
    .bv-content-slider-container{
        background-color: $slider-bar-background-color !important;
        height: rem(4) !important;
        border-radius: rem(4) !important;
    }
    li.bv-content-slider-segment{
        height: rem(4) !important;
    }
    .bv-secondary-rating-summary-list.bv-table{
        display: flex !important;
        flex-direction: column;
    }
    .bv-secondary-rating-summary-bars-container.bv-table-row{
        display: flex !important;
        flex-direction: column;
    }
    .bv-secondary-rating-summary-list.bv-table .bv-table-row span.bv-table-cell:first-of-type {
        width: 100%!important;
        @include media-breakpoint-up(md) {
            width: 60%!important;
        }
        padding-left: 0!important;
    }
    .bv-content-slider-halfway{
        height: rem(4) !important;
        margin-top:0 !important;
        display: none !important;
    }
    .bv-content-slider-value{
        height: rem(6) !important;
        margin-top: rem(-1) !important;
        border-radius: rem(4) !important;
        width: rem(40) !important;
        box-shadow: none !important;
    }
    .bv-inline-histogram-ratings .bv-flex-container-column{
        margin-left: 0 !important;
        @include media-breakpoint-down(md) {
            width: 100%!important;
        }
    }
    /********************************
    *Control bar (filters controls bar) 
    */
    .bv-control-bar{
        border-top:rem(1) solid $ratings-filter-border-color !important;
        border-bottom: rem(1) solid $ratings-filter-border-color !important;
    }
    .bv-dropdown-title{
        font-weight: normal !important;
        font-family: $gotham-bold;
    }

    .bv-author-profile .bv-author-cdv{
        display: flex !important;
    }
    .bv-author-profile .bv-author-cdv .bv-author-userinfo-data{
        display: inline-block !important;
        width: 50% !important;
    }
    .bv-control-bar-count{
        @include media-breakpoint-down(md) {
            text-align: initial !important;
        }
    }
    /****************
    *Rating Snapshot 
    */
    .bv-content-secondary-ratings-container{
        height: rem(8) !important;
        box-shadow: none !important;
    }
    .bv-content-secondary-ratings-value {
        height: rem(8) !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .bv-glyph {
        font-size: rem(14) !important;
        color: $ratings-stars-color !important;
    }
    .bv-filters .bv-content-filter-select-element {
        @include media-breakpoint-down(md) {
            font-size: rem(14) !important;
        }
    }
    .bv-write-review-label {
        text-decoration: underline !important;
        text-transform: none !important;
    }
    div[role='presentation'] div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(2) > div {
        color: $bv-text-color !important;
        font-family: $gotham-regular;
        font-size: rem(12);
    }
    div > h2 {
        color: $bv-text-color;
    }
    section[aria-label='Rating Snapshot'] h3,
    section[aria-label='Overall Rating'] h3 {
        display: none;
    }
    section[aria-label='Overall Rating'] div {
        margin-left: 0;
        padding-left: 0;
        flex-direction: row;
    }
    .bv_content_label_row:nth-of-type(1) {
        color: $bv-text-color;
        font-size: rem(14);
    }
    div[data-bv-v='contentItem'] .bv_content_label_row:nth-of-type(1) {
        font-size: rem(12);
        font-family: $gotham-regular;
    }
    > div > div:nth-of-type(2) > div:nth-of-type(1) {
        flex-direction: column !important;
        display: flex;
        position: relative;
    }
    .bv_rating_content1 {
        padding-bottom: rem(24);
    }
    .bv_rating_content3 > div h3 {
        padding-left: 0;
        margin-left: 0;
    }
    .bv_rating_content2 {
        &::before {
            content: 'Rating Snapshot';
            color: $bv-text-color;
            font-family: $gotham-semibold;
            font-size: rem(14);
        }
    }
    section[aria-label='Overall Rating'] div > div > div:nth-of-type(1) {
        color: $bv-text-color;
        font-family: $gotham-semibold;
        font-size: rem(14);
    }
    section[aria-label='Overall Rating'] div > div > div:nth-of-type(2) {
        flex-direction: row;
    }
    .bv_rating_content1 section[aria-label='Rating Snapshot'] > div {
        margin-left: 0;
        padding-left: 0;
        > div:nth-of-type(1) {
            padding-bottom: 0 !important;
        }
    }
    li span > span:first-of-type > svg {
        top: 0;
    }
    #reviews_container {
        .bv-feedback-container button path {
            stroke: $bv-star-color;
        }
        div[data-bv-v='inlineProfile'] span:nth-of-type(2) {
            font-family: $gotham-regular;
        }
        div[data-bv-v='contentHeader'] h3 {
            font-size: rem(14);
        }
        div[data-bv-v='contentHeader'] div span {
            font-size: rem(12);
        }
    }
    > div > div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(2) > section > div > div > div:nth-of-type(1) {
        position: relative;
        top: rem(-5);
    }
    stop:nth-child(1) {
        stop-color: $bv-star-color;
    }
    .toggle-filter-btn {
        background: $bv-page-background-color;
        position: relative;
        display: block;
        color: $bv-page-background-color;
        padding: rem(24);
        text-decoration: none;
        margin-bottom: rem(5);
        &.active {
            .lp-icon-caret {
                &::before {
                    transform: rotate(-45deg);
                }
                &::after {
                    transform: rotate(-135deg);
                }
            }
        }
        .lp-icon-caret {
            &::before {
                transform: rotate(0deg);
            }
        }
        .lp-icon-caret {
            &::after {
                left: rem(6);
                top: rem(-0.7);
                transform: rotate(45deg);
            }
        }
    }
    .filter-reviews-container {
        display: flex;
        justify-content: space-between;
        margin-top: rem(8);
        h3 {
            padding-top: rem(12);
        }
        + div {
            @include media-breakpoint-up(md) {
                width: 70%;
                display: grid;
                gap: rem(1);
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    .filter-review-border {
        border-bottom: rem(1) solid $bv-border-color !important;
        margin-bottom: rem(16);
    }
    .bv_rating_content3 div[role='radio'] {
        div {
            border: rem(1) solid $bv-button-border-color !important;
        }
        path {
            stroke: $bv-star-color !important;
        }
    }
    .bv-description-hr {
        border-top: rem(1) solid $bv-border-color;
    }
    .description-and-detail {
        padding-top: rem(24);
        @include media-breakpoint-up(sm) {
            padding-top: rem(76);
        }
    }
    > div > div:nth-of-type(1) {
        padding-top: rem(25);
    }
    button[title='Rating'],
    button[title='BodyTypeDropdown'],
    button[title='FitDropdown_1'],
    button[title='AvgSizeDropdown_1'],
    button[title='AgeDropdown_1'],
    button[title='SizePurchasedDropdown_1'] {
        background-color: $bv-button-background-color !important;
    }
    div[direction='row'][wrap='nowrap'] {
        margin-bottom: rem(10);
        > div > div:nth-of-type(3) svg path {
            stroke: $bv-star-color;
            fill: $bv-star-color !important;
        }
    }
    .bv-feedback-container {
        button[aria-label^='Yes']::before {
            content: 'Yes';
            font-size: rem(12);
            color: $bv-text-color;
            line-height: rem(24);
            padding-top: rem(4);
        }
        button[aria-label^='Yes'] svg {
            display: none;
        }
        button[aria-label^='No']::before {
            content: 'No';
            font-size: rem(12);
            color: $bv-text-color;
            line-height: rem(24);
            padding-top: rem(4);
        }
        button[aria-label^='No'] svg {
            display: none;
        }
        button[aria-label^='No']:disabled {
            color: $bv-unselected-button-color;
        }
        button[aria-label^='Yes']:disabled {
            color: $bv-selected-button-color;
        }
        button {
            color: $bv-text-color;
        }
    }
    div[role='radiogroup'][aria-label='Rating is 0 (out of 5 stars).'] + div {
        font-size: rem(14);
        color: $bv-text-color;
        font-family: $gotham-regular;
    }
    .bv_rating_content3 h3 + div {
        padding-left: 0 !important;
        margin-left: 0 !important;
    }
    .bv_rating_content3 div[role='group'] div[role='button'] div {
        border: rem(1) solid $bv-button-border-color !important;

        svg>g>g {
            stroke: $bv-button-border-color !important;
        }
    }

    .bv_rating_content1 div[role='presentation'] div div {
        background-color: $bv-button-background-color !important;
    }
}
.bv-description-hr + .description-and-detail {
    margin-top: rem(64);
    @include media-breakpoint-up(sm) {
        margin-top: rem(24) !important;
    }
}
[data-bv-show='rating_summary'] .bv_main_container button.bv_button_buttonMinimalist,
[data-bv-show='rating_summary'] .bv_main_container button.bv_button_buttonMinimalist:hover {
    text-decoration: underline !important;
}
.bv_modal_outer_content button.bv_button_buttonFull {
    background-color: $bv-button-background-color !important;
    &:hover {
        background-color: $bv-button-background-color !important;
    }
}
.active-star-background {
    background-color: $bv-button-background-color !important;
}
div[data-bv-show='inpage_submission'] div[role='group'] div[role='button'] {
    border: rem(1) solid $bv-button-border-color !important;
    &:hover {
        background-color: $bv-button-background-color !important;
    }
    svg path {
        stroke: $bv-star-color !important;
    }
}
div[data-bv-show='inpage_submission'] div[role='radiogroup'] > div[role='radio'] {
    color: $bv-button-border-color;
    border: rem(1) solid $bv-button-border-color !important;
}
div[data-bv-show='inpage_submission'] div[role='radiogroup'] > div[role='radio'][aria-checked='true'] {
    background-color: $bv-button-background-color !important;
    color: $bv-review-modal-button-text;
}
#bv-ips-submit-label + button {
    background-color: $bv-button-background-color !important;
    border: rem(1) solid $bv-button-background-color !important;
}
#bv-ips-step-0 > div:nth-of-type(1) {
    > div:nth-of-type(1),
    span {
        color: $bv-text-color;
        border: rem(1) solid $bv-button-border-color;
    }
}
.bv-center-align-text {
    margin: auto !important;
    text-align: center !important;
}
.center-stars-class {
    margin: rem(16) auto rem(16) !important;
    justify-content: center !important;
    border: none !important;
    text-align: center !important;
}
.bv_modal_component_container .bv_modal_inner_content .bv_histogram_row_full_bar .bv_histogram_row_bar_filled {
    background-color: $ratings-stars-color !important;
}
#bazaarvoiceReviews section[aria-label="Overall Rating"] div>div>div:first-of-type {
    line-height: rem(31);
    min-width: rem(30);
}

.bv_rating_content3>div>div:nth-of-type(2) {
    margin-left: 0;
    padding-left: 0;
    white-space: nowrap;
    padding-bottom: rem(32);
}

#bazaarvoiceReviews .bv_rating_content3 h3+div {
    margin: 0 !important;
    padding: 0 !important;
}

div[data-bv-v="contentItem"]>div[data-bv-v="inlineProfile"]+div {
    display: block;
}

.clicked-background {
    background-color: $ratings-stars-color !important;
}

#bv_review_maincontainer {
    >div:nth-of-type(3) {
        border-bottom: none !important;
    }

    >div:nth-of-type(2)>div:nth-of-type(2)>div>div>div:nth-of-type(2)>div>div:first-of-type {
        width: rem(328);
    }

    div[width="23.75%"]:nth-of-type(5),
    div[width="23.75%"]:nth-of-type(1) {
        border-radius: 0 rem(20) rem(20) 0;
    }

    div[width="23.75%"] {
        width: 20%;
        height: rem(4) !important;
    }

    div[width="5%"] {
        width: 20%;
        border-radius: rem(5);
        height: rem(4) !important;
    }

    div[width="5%"] {
        background-color: $ratings-stars-color !important;
    }

    >div:nth-of-type(2)>div:first-of-type {
        flex-direction: column !important;
        margin: 0 !important;
    }
    .filter-review-border button[aria-label*="Remove active filter"] {
        background-color: $bv-button-background-color !important;
    }
}
