.reset-password-request-modal {
    @include media-breakpoint-up(md) {
        .modal {
            .modal-header {
                padding-bottom: rem(24);
            }
        }
    }
}

.request-password-title {
    margin: rem(40) 0;
    font-family: $crimson;
    font-size: rem(32);
    line-height: rem(40);
    color: $forgot-password-header-text-color;
    text-align: center;
    border: 0;
    padding-bottom: 0;
}

.request-password-description {
    margin-bottom: rem(40);
    line-height: rem(20);
}

.send-email-btn {
    #submitEmailButton {
        position: relative;
        bottom: initial;
        height: rem(56);
        margin-top: rem(40);
    }
}

.reset-password-success {
    .success-message + .close-modal-btn {
        position: relative;
        bottom: initial;
        margin-top: rem(32);
    }
}

@include media-breakpoint-up(md) {
    .request-password-title {
        margin: rem(96) 0 rem(40);
    }

    .request-password-description {
        margin-bottom: rem(24);
    }
}

.reset-password-success {
    display: none;
}

.request-success {
    .modal-header {
        .modal-header-title {
            display: none;
        }
    }

    .reset-password-form {
        display: none;
    }

    .reset-password-success {
        display: block;
        margin-top: rem(136);
    }
}

.success-message {
    margin-top: rem(16);
    margin-bottom: 0;
}

.close-modal-btn {
    margin-top: rem(40);
    height: rem(56);
}

@include media-breakpoint-up(md) {
    .success-message {
        margin-top: rem(8);
    }

    .close-modal-btn {
        margin-top: rem(32);
    }

    .request-success {
        .reset-password-success {
            margin-top: rem(184);
        }
    }
}
