.loyalty-enroll-now-container,
.loyalty-welcome-popup {
    top: rem(20);
    padding-right: 0 !important;

    @include media-breakpoint-down(sm) {
        top: 0;
    }
}

@include media-breakpoint-down(sm) {
    .full-sheet-modal.modal {
        padding: 0 !important;

        .modal-dialog {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            position: fixed;
        }

        .modal-content {
            height: 100%;
            min-height: 100%;
            border-radius: 0;
            position: absolute;
            top: 0;
            overflow-y: auto;
        }
    }
}

.loyalty-enrollnow-dialog,
.loyalty-welcome-dialog {
    &.modal-dialog {
        max-width: unset;
        margin: 0;
    }

    >.modal-content {
        border: transparent;

        >.modal-body {
            max-height: none;
            overflow-y: visible;
            padding: 0;
        }
    }

    .modal-header {
        background-color: inherit;
    }

    .modal-body {
        max-height: unset;
    }

    .close-enrollnow-model,
    .close-welcome-modal {
        padding-top: rem(16);
        line-height: rem(16);

        @include media-breakpoint-down(sm) {
            padding-top: rem(3);
        }
    }

    @include media-breakpoint-up(md) {
        &.modal-dialog {
            max-width: rem(960);
            margin: rem(8) auto;
        }

        >.modal-content {
            >.modal-body {
                padding: 0;
                max-height: rem(656);
            }
        }

        .close-enrollnow-model,
        .close-welcome-modal {
            padding-right: rem(16);
        }

        .enroll-image-section {
            height: 100%;
            max-height: rem(656);
        }
    }

    .drawer-image-loyalty {
        img {
            max-width: 100%;
            min-height: 100%;
            object-fit: cover;

            @include media-breakpoint-down(sm) {
                min-height: rem(170);
            }

        }
    }

    .loyalty-getaccess-section {
        color: $base-text-color;
        font-family: $crimson;
        padding: rem(48);

        @include media-breakpoint-down(sm) {
            padding: rem(16);
        }

        &.loyalty-welcome-msg {
            padding: rem(73) rem(68) rem(40);

            @include media-breakpoint-down(sm) {
                padding: rem(32) rem(16);
            }

            .welcome-cta {
                margin-top: rem(127);

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }

        .loyalty-getaccess-title {
            font-size: rem(24);
            line-height: rem(32);
            font-family: $crimson;
            margin: 0;
        }

        .loyalty-getaccess-benefits {
            padding-top: rem(16);
        }

        .loyalty-getaccess-list {
            list-style-type: disc;
            letter-spacing: rem(0.6);
            line-height: rem(24);
            font-size: rem(18);
            margin-left: rem(15);
            padding: rem(8) 0;
        }

        .postal-code-msg {
            font-family: $gotham-regular;
            line-height: rem(16);
            letter-spacing: rem(0.4);
            font-size: rem(12);
            padding-top: rem(16);
            padding-bottom: rem(8);
        }

        .custom-form-group {
            margin: 0;
            padding-bottom: rem(24);

            &.margin-adjustment {
                @include media-breakpoint-down(sm) {
                    margin-top: rem(72);
                }
            }
        }

        .custom-control-input.is-invalid~.custom-control-label {

            &.loyalty-terms-conditions-label,
            &:before {
                color: $base-text-color;
                border-color: $base-text-color;
            }

        }

        .terms-conditions,
        .loyalty-terms-conditions-label {
            font-size: rem(12);
            font-family: $gotham-regular;
            line-height: rem(24);
            letter-spacing: rem(0.4);
            margin-bottom: rem(16);

            .loyalty-program-terms-and-condition {
                font-size: rem(12);
            }
        }

        .loyalty-terms-error {
            font-size: rem(12);
            line-height: rem(16);
            font-family: $gotham-regular;
            color: $error-text-color;
            position: absolute;
            margin-top: 0;
            left: 0;
            bottom: rem(8);
        }

        .welcome-copy {
            padding: rem(16) 0 rem(24);

            @include media-breakpoint-down(sm) {
                padding-bottom: rem(80);
            }
        }

        .welcome-paragraph {
            font-size: rem(14);
            line-height: rem(20);
            font-family: $gotham-regular;
            color: $base-text-color;
            margin: 0;
            padding: rem(16) 0;
        }

        .guest-enrollment {
            padding-top: rem(150);

            @include media-breakpoint-down(sm) {
                padding-top: rem(120);
            }
        }
    }

    .mobile-loyalty-enroll-now-title,
    .mobile-loyalty-welcome-title {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(1);
        font-family: $gotham-bold;
        color: $base-text-color;
        margin: 0;
        text-transform: uppercase;
    }

}

.loyalty-guest-refer-friend-modal,
.loyalty-digital-refer-friend-modal {
    .loyalty-friend-referral-dialog {
        max-width: rem(960);
    }

    .modal-content {
        border: none;
    }

    .modal-body {
        padding: 0;
    }

    @include media-breakpoint-up(md) {
        .modal-dialog {
            max-width: rem(960);
            margin: rem(8) auto;
        }

        .modal-body {
            overflow-y: visible;
            max-height: rem(480);
        }
    }

    .refer-friend-guest-image,
    .refer-friend-digital-image {
        height: 100%;

        .drawer-image {
            max-width: 100%;
            object-fit: cover;
            height: rem(136);
            width: 100%;

            @include media-breakpoint-up(md) {
                height: rem(480);
            }
        }
    }

    .close-guest-referral-mobile {
        line-height: rem(16);
        padding: rem(18) rem(16) rem(20);
    }

    .close-referral-guest-modal-icon,
    .close-referral-digital-modal-icon {
        padding: rem(20) rem(16) rem(20) 0;
        line-height: rem(16);
        z-index: $zindex-lowest;
        position: inherit;

        .close-referral-icon {
            display: inline-block;
            width: rem(16);
            height: rem(16);
        }

        @include media-breakpoint-up(md) {
            padding-top: rem(16);
            padding-bottom: 0;
        }
    }

    .close-mobile-loyalty-digital-modal-title {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(1);
        font-family: $gotham-bold;
        color: $base-text-color;
        margin: 0;
        text-transform: uppercase;
    }

    .refer-friend-right-section {
        padding: rem(24) rem(16) 0;

        @include media-breakpoint-up(md) {
            padding: rem(84) rem(40);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .refer-modal-header {
            font-size: rem(24);
            font-family: $crimson;
            font-weight: 400;
            line-height: rem(32);
            color: $loyalty-border-color;
            text-align: left;
            margin: 0 rem(4);

            @include media-breakpoint-up(md) {
                margin: 0;
                margin-right: rem(16);
                line-height: rem(40);
                font-size: rem(32);
            }
        }

        .friend-referral-content {
            font-size: rem(14);
            font-family: $gotham-regular;
            line-height: rem(20);
            text-align: left;
            margin: rem(8) rem(4) 0;

            @include media-breakpoint-up(md) {
                margin: rem(16) rem(16) 0 0;
            }
        }

        .friend-referral-cta {
            margin-top: rem(40);
            font-size: rem(14);
            font-family: $gotham-bold;
            font-weight: 500;
            height: rem(56);

            .friend-referral-btn {
                width: 100%;
                height: 100%;
                border: none;
                line-height: rem(24);
                font-size: rem(14);
                font-weight: 500;
                padding: rem(16) rem(67);
            }
        }

        .loyalty-terms-link {
            padding-top: rem(16);
            font-size: rem(12);
            font-family: $gotham-regular;
            letter-spacing: rem(0.4);
            text-align: left;

            a {
                line-height: rem(24);
                color: $loyalty-referral-color;
            }
        }
    }

    .refer-friend-digital-right-section {
        padding: rem(24) rem(16) 0;

        @include media-breakpoint-up(md) {
            padding: rem(44) rem(40);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .refer-digital-modal-header {
            font-size: rem(24);
            font-family: $crimson;
            font-weight: 400;
            line-height: rem(32);
            color: $loyalty-border-color;
            text-align: left;
            margin: 0 rem(4);

            @include media-breakpoint-up(md) {
                margin: 0;
                margin-right: rem(16);
                line-height: rem(40);
                font-size: rem(32);
            }
        }

        .refer-digital-modal-content {
            font-size: rem(14);
            font-family: $gotham-regular;
            line-height: rem(20);
            text-align: left;
            margin: rem(16) rem(4) 0;

            @include media-breakpoint-up(md) {
                margin: rem(16) rem(16) 0 0;
            }
        }

        .friend-referral-digital-cta {
            margin-top: rem(40);
            font-size: rem(14);
            font-family: $gotham-bold;
            font-weight: 500;
            height: rem(56);

            .friend-referral-digital-btn {
                width: 100%;
                height: 100%;
                border: none;
                line-height: rem(24);
                font-size: rem(14);
                font-weight: 500;
                padding: rem(16) rem(55);

                @include media-breakpoint-up(md) {
                    padding: rem(16) rem(67);
                }
            }
        }

        .loyalty-terms-link {
            padding-top: rem(16);
            font-size: rem(12);
            font-family: $gotham-regular;
            letter-spacing: rem(0.4);
            text-align: left;

            a {
                line-height: rem(24);
                color: $loyalty-referral-color;
            }
        }
    }
}
