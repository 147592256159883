/*
    Usage:
    <label class="switch">
        <span class="sr-only">Pick Up in Store</span>
        <input type="checkbox" />
        <span class="slider"></span>
    </label>
*/

$switch-width: rem(44);
$switch-height: rem(24);

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: $switch-width;
    height: $switch-height;
    margin-bottom: rem(0);

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:focus + .slider {
            box-shadow: 0 0 1px $mulled-wine;
        }

        &:checked + .slider {
            background-color: $switch-on-bg-color;
        }

        &:checked + .slider:before {
            transform: translateX(rem(20));
        }
    }
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    background-color: $switch-bg-color;
    border-radius: rem(12);
    border: rem(1) solid $switch-border-color;

    &:before {
        position: absolute;
        content: '';
        height: rem(22);
        width: rem(22);
        transition: 0.4s;
        background-color: $switch-bg-color;
        border-radius: 50%;
        border: rem(1) solid $switch-border-color;
    }
}
